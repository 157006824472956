<template>
  <DataTable :value="docs" responsiveLayout="scroll" class="p-datatable-sm">
    <Column field="document.title" header="Nom du fichier"></Column>
    <Column field="month" header="Mois"></Column>
    <Column field="year" header="Année"></Column>
  </DataTable>
</template>

<script>
export default {
  name: "TableauDS",
  data () {
    return {
      docs: []
    }
  },
  async created () {
    this.docs.push({document: {title: '004'}, month: 'Juin', year: '2021'})
    this.docs.push({document: {title: '005'}, month: 'Juin', year: '2021'})
    this.docs.push({document: {title: '006'}, month: 'Juillet', year: '2021'})
  }
}
</script>

<style scoped>

</style>
