<template>
  <div v-if="localIndependent" class="px-1 py-1 md:px-1 lg:px-1 flex-container non-scrollable">
    <div class="grid scrollable">
      <div class="col-12 lg:col-4">
        <independent-documents
            ref="independentDocuments"
            :documents-types="documentTypes"
            :independent-id="localIndependent.id"
        />
      </div>
      <div class="lg:col-8">
        <div class="col-12 pt-0 ">
          <tableau-documents ref="documents" :static-index="0" :nsecu="localIndependent.socialSecurityCode"/>
        </div>
        <div class="col-12">
          <work-contracts-amendments ref="workContractsAmendments" :independentId="localIndependent.id"/>
        </div>
        <div class="col-12 ">
          <independent-other-documents ref="independentOtherDocument" :independentId="localIndependent.id"/>
        </div>
      </div>
    </div>
    <Dialog :draggable="false" dismissableMask header="Action" v-model:visible="displayAction" :style="{width: '500px'}"
            :modal="true">
      <div class="confirmation-content">
        <span>Quelle action souhaitez-vous réaliser ?</span>
      </div>
      <template #footer>
        <div v-if="currentAction === 'ibanSalary'" class="field">
          <label for="ibanSalary">Iban Salaire *</label>
          <InputText id="ibanSalary" type="text" v-model="localIndependent.profil.workContract.ibanSalary"/>
        </div>
        <div v-else-if="currentAction === 'ibanNdf'" class="field">
          <label for="ibanNdf">Iban Ndf *</label>
          <InputText id="ibanNdf" type="text" v-model="localIndependent.profil.workContract.ibanNdf"/>
        </div>
        <div v-else-if="currentAction === 'ibanBoth'">
          <div class="field">
            <label for="ibanBoth">Iban *</label>
            <InputText id="ibanBoth" type="text" v-model="ibanBoth"/>
          </div>
        </div>
        <div v-if="localIndependent.documents[currentAction]" class="flex justify-content-evenly mt-4">
          <FileUpload v-if="hasAccess(this.$route.name, true)" mode="basic" choose-label="Remplacer" auto custom-upload @before-upload="checkFields"
                      @uploader="(file) => { upload(file); this.displayAction = false }" class="p-button-rounded"/>
          <Button label="Télécharger"
                  @click="download(localIndependent.documents[currentAction]); this.displayAction = false"
                  icon="pi pi-download" class="p-button-rounded"/>
        </div>
        <div v-else class="flex justify-content-evenly mt-4">
          <FileUpload mode="basic" choose-label="Charger" auto custom-upload @before-upload="checkFields"
                      @uploader="(file) => { upload(file); this.displayAction = false }" class="p-button-rounded"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import TableauDocuments from "@/components/RH/TableauDocuments";
import Alert from "@/utils/Alert";
import IndependentDocuments from "@/components/RH/IndependentDocuments.vue";
import {getDocumentTypes} from "@/utils/IndependentUtil";
import {disablePComponentsOfCurrentPage, hasAccess} from "@/utils/Misc";
import WorkContractsAmendments from "@/components/Contracts/WorkContractsAmendments.vue";
import IndependentOtherDocuments from "@/components/IndependentOtherDocuments.vue";

export default {
  name: 'DocumentsRH',
  components: {IndependentOtherDocuments, WorkContractsAmendments, IndependentDocuments, TableauDocuments},
  data() {
    return {
      hasAccess: hasAccess,
      displayAction: false,
      localIndependent: null,
      currentAction: null,

      documentTypes: [],
      ibanBoth: '',
      fieldsValidated: false,
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
      settings: state => state.misc.settings,
      me: state => state.auth.me,
    }),
    ...mapGetters({
      independent: 'independent/getIndependent',
    })
  },
  mounted () {
    this.$nextTick(() => {
      if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
    })
  },
  created() {
    this.localIndependent = JSON.parse(JSON.stringify(this.independent))
    this.documentTypes = getDocumentTypes({
      independent: this.localIndependent, constants: this.constants,
      settings: this.settings, rhView: true
    })
  },
  watch: {
    independent() {
      this.localIndependent = JSON.parse(JSON.stringify(this.independent))
      process.nextTick(() => {
        // update all the used components
        if (this.$refs?.independentDocuments) this.$refs.independentDocuments.initDocuments()
        if (this.$refs?.documents) this.$refs.documents.initDocs()
        if (this.$refs?.workContractsAmendments) this.$refs.workContractsAmendments.initContracts()
        if (this.$refs?.independentOtherDocument) this.$refs.independentOtherDocument.initDocuments()
      })
    }
  },
  methods: {
    action(object) {
      this.displayAction = true
      this.currentAction = object
    },
    async download(object) {
      var file = await this.$store.dispatch('file/downloadFile', object)
      const blob = new Blob([new Uint8Array(file.data.data)], {type: file.mimetype})
      const link = document.createElement('a')
      link.download = file.filename
      const url = URL.createObjectURL(blob)
      link.href = url
      link.click()
      URL.revokeObjectURL(url)
    },
    async upload(file) {
      if (!this.checkFields()) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(*)'})
        return
      }
      const form = new FormData()
      form.append('filename', file.files[0].name)
      form.append('file', file.files[0], file.files[0].name)

      const payload = Object.assign({}, this.localIndependent)

      payload.profil.workContract.ibanType = this.localIndependent.profil.workContract.ibanType
      if (this.localIndependent.profil.workContract.ibanType.value === this.constants.IBAN_TYPE.BOTH) {
        payload.profil.workContract.ibanSalary = this.ibanBoth
        payload.profil.workContract.ibanNdf = this.ibanBoth
      } else if (this.localIndependent.profil.workContract.ibanType.value !== this.constants.IBAN_TYPE.BOTH) {
        payload.profil.workContract.ibanSalary = this.localIndependent.profil.workContract.ibanSalary
        payload.profil.workContract.ibanNdf = this.localIndependent.profil.workContract.ibanNdf
      }

      await this.$store.dispatch('independent/updateIndependent', {id: this.localIndependent.id, payload: payload})
    },
    // todo: for the IBAN remake -- should open a separate pop up at the validation of the IBAN type
    checkFields() {
      if (this.currentAction === 'ibanBoth' && !this.ibanBoth) {
        return false
      }
      if (this.currentAction === 'ibanSalary' && (!this.localIndependent.profil.workContract.ibanSalary || this.localIndependent.profil.workContract.ibanSalary === '')) {
        return false
      }
      if (this.currentAction === 'ibanNdf' && (!this.localIndependent.profil.workContract.ibanNdf || this.localIndependent.profil.workContract.ibanNdf === '')) {
        return false
      }
      return true
    }
  }
}
</script>

<style>
.p-dialog .p-dialog-header {
  border-bottom: none !important;
}

.p-dialog .p-dialog-footer {
  border-top: none !important;
}
</style>
