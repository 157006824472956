<template>
  <Card class="shadow-2">
    <template #title>
      <div class="flex justify-content-center align-items-center">
        <div v-if="staticIndex === undefined" @click="decreaseIndex" class="cursor-pointer"><img :src="require('@/assets/images/icon/leftCarret.svg')" width="16" height="16"/></div>
        <div v-if="staticIndex !== undefined"><i class="fa fa-file-signature mr-2"/></div>
        <div class="text-900 text-xl font-medium mr-4 ml-4 text-center">{{ index === 0 ? 'Bulletins de salaire' : index === 1 ? 'Notes de frais validées' : 'Dispotifs sociaux' }}</div>
        <div v-if="staticIndex === undefined" @click="increaseIndex" class="cursor-pointer"><img :src="require('@/assets/images/icon/rightCarret.svg')" width="16" height="16"/></div>
      </div>
    </template>
    <template #content>
      <div class="col-12">
        <tableau-b-d-s v-if="index === 0" :docs="docs.filter(doc => doc.type === constants.DOCUMENT_TYPE.PAY_SLIP)" @refresh="initDocs"/>
        <tableau-n-d-f v-if="index === 1"></tableau-n-d-f>
        <tableau-d-s v-if="index === 2"></tableau-d-s>
      </div>
    </template>
  </Card>
</template>

<script>
import TableauBDS from "@/components/RH/TableauBDS";
import TableauNDF from "@/components/RH/TableauNDF";
import TableauDS from "@/components/RH/TableauDS";
import {mapState} from "vuex";

export default {
  components: {TableauDS, TableauNDF, TableauBDS},
  props: ["nsecu", "staticIndex"],
  name: "TableauDocuments",
  data () {
    return {
      index: this.staticIndex?? 0,
      docs: []
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants
    })
  },
  async created () {
    await this.initDocs()
  },
  methods: {
    async initDocs () {
      this.docs = await this.$store.dispatch('documents/getIndependentDocumentBySecuritySocialCode', { socialSecurityCode: this.nsecu })
    },
    increaseIndex () {
      this.index = (this.index + 1) % 3
    },
    decreaseIndex () {
      this.index = (this.index - 1) % 3
    }
  }
}
</script>

<style scoped>

</style>
