<template>
  <!--  todo: mettre à jour les données restantes en dur  -->
  <div class="flex pr-3 pb-5 h-full">
    <div class="flex flex-row gap-3 w-full" :class="me.role.name === constants.ROLES.PROSPECT ? 'justify-content-center' : ''">
      <div class="flex flex-column left-cards cards gap-3">
        <div v-if="me.role.name === constants.ROLES.PROSPECT" class="text-center">
          <p class="text-2xl">{{ step.label }}</p>
        </div>
        <OuterCardTitle value="Informations personnelles"/>
        <Card>
          <template #content>
            <div class="grid formgrid p-fluid">
              <div :class="v$.localIndependent.profil.civilStatus.$dirty && v$.localIndependent.profil.civilStatus.$errors.length ? 'mb-1' : ''"
                   class="field mb-4 col-12 md:col-12">
                <label for="civilStatus-default" :class="{'p-error':v$.localIndependent.profil.civilStatus.$errors.length}">
                  {{ $t('civilStatus') }} *
                </label>
                <div class="flex justify-content-evenly">
                  <div class="flex gap-1 align-items-center">
                    <span for="civilStatus-default">{{ $t('male') }}</span>
                    <RadioButton id="civilStatus-default" v-model="v$.localIndependent.profil.civilStatus.$model"
                                 value="Male"
                                 :class="{'p-invalid':v$.localIndependent.profil.civilStatus.$errors.length}"
                                 @update:modelValue="changeProfileFields"/>
                  </div>
                  <div class="flex gap-1 align-items-center">
                    <label for="civilStatus">{{ $t('female') }}</label>
                    <RadioButton id="civilStatus" v-model="v$.localIndependent.profil.civilStatus.$model"
                                 value="Female"
                                 :class="{'p-invalid':v$.localIndependent.profil.civilStatus.$errors.length}"
                                 @update:modelValue="changeProfileFields"/>
                  </div>
                </div>
              </div>
              <div v-if="v$.localIndependent.profil.civilStatus.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.civilStatus.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div class="field mb-4 col-12 md:col-12"
                   :class="v$.localIndependent.last_name.$dirty && v$.localIndependent.last_name.$errors.length ? 'mb-1' : ''">
                <label for="name" :class="{'p-error':v$.localIndependent.last_name.$errors.length}">
                  {{ $t('lastname') }} *
                </label>
                <InputText id="name" type="text" v-model="v$.localIndependent.last_name.$model"
                           :class="{'p-invalid':v$.localIndependent.last_name.$errors.length}"
                           @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.last_name.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.last_name.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div class="field mb-4 col-12 md:col-12"
                   :class="v$.localIndependent.first_name.$dirty && v$.localIndependent.first_name.$errors.length ? 'mb-1' : ''">
                <label for="last-name" :class="{'p-error':v$.localIndependent.first_name.$errors.length}">
                  {{ $t('firstname') }} *
                </label>
                <InputText id="last-name" type="text" v-model="v$.localIndependent.first_name.$model"
                           :class="{'p-invalid':v$.localIndependent.first_name.$errors.length}"
                           @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.first_name.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.first_name.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div class="field mb-4 col-12 md:col-12">
                <label for="email">{{ $t('email') }} *</label>
                <InputText id="email" type="text" v-model="localIndependent.email" disabled/>
              </div>
              <div class="field mb-4 col-12 md:col-12"
                   :class="v$.localIndependent.phone.$dirty && v$.localIndependent.phone.$errors.length ? 'mb-1' : ''">
                <label for="phone" :class="{'p-error':v$.localIndependent.phone.$errors.length}">
                  {{ $t('phone') }} *
                </label>
                <InputMask id="phone" v-model="v$.localIndependent.phone.$model" mask="99-99-99-99-99"
                           :class="{'p-invalid':v$.localIndependent.phone.$errors.length}"
                           @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.phone.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.phone.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div class="field mb-4 col-12 md:col-12"
                   :class="v$.localIndependent.profil.address.$dirty && v$.localIndependent.profil.address.$errors.length ? 'mb-1' : ''">
                <label for="address" :class="{'p-error':v$.localIndependent.profil.address.$errors.length}">
                  {{ $t('address') }} *
                </label>
                <InputText id="address" type="text" v-model="v$.localIndependent.profil.address.$model"
                           :class="{'p-invalid':v$.localIndependent.profil.address.$errors.length}"
                           @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.profil.address.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.address.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div class="field mb-4 col-12 md:col-4"
                   :class="v$.localIndependent.profil.zipCode.$dirty && v$.localIndependent.profil.zipCode.$errors.length ? 'mb-1' : ''">
                <label for="zip" :class="{'p-error':v$.localIndependent.profil.zipCode.$errors.length}">
                  {{ $t('postalCode') }} *
                </label>
                <InputMask id="zip" v-model="v$.localIndependent.profil.zipCode.$model"
                           :class="{'p-invalid':v$.localIndependent.profil.zipCode.$errors.length}"
                           mask="99999"
                           @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.profil.zipCode.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.zipCode.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div class="field mb-4 col-12 md:col-8"
                   :class="v$.localIndependent.profil.city.$dirty && v$.localIndependent.profil.city.$errors.length ? 'mb-1' : ''">
                <label for="city" :class="{'p-error':v$.localIndependent.profil.city.$errors.length}">
                  {{ $t('city') }} *
                </label>
                <InputText id="city" type="text" v-model="v$.localIndependent.profil.city.$model"
                           :class="{'p-invalid':v$.localIndependent.profil.city.$errors.length}"
                           @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.profil.city.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.city.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div class="field mb-4 col-12 md:col-12"
                   :class="v$.localIndependent.profil.country.$dirty && v$.localIndependent.profil.country.$errors.length ? 'mb-1' : ''">
                <label for="country" :class="{'p-error':v$.localIndependent.profil.country.$errors.length}">
                  {{ $t('country') }} *
                </label>
                <InputText id="country" type="text" v-model="v$.localIndependent.profil.country.$model"
                           :class="{'p-invalid':v$.localIndependent.profil.country.$errors.length}"
                           @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.profil.country.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.country.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div class="field mb-4 col-12 md:col-6"
                   :class="v$.localIndependent.profil.birthPlace.$dirty && v$.localIndependent.profil.birthPlace.$errors.length ? 'mb-1' : ''">
                <label for="birthPlace" :class="{'p-error':v$.localIndependent.profil.birthPlace.$errors.length}">
                  {{ $t('birthPlace') }} *
                </label>
                <InputText id="birthPlace" v-model="v$.localIndependent.profil.birthPlace.$model"
                           :disabled="me.role.name === constants.ROLES.INDEPENDENT && isIndependentContractValidated" type="text"
                           :class="{'p-invalid':v$.localIndependent.profil.birthPlace.$errors.length}"
                           @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.profil.birthPlace.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.birthPlace.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div class="field mb-4 col-12 md:col-6"
                   :class="v$.localIndependent.profil.birthDate.$dirty && v$.localIndependent.profil.birthDate.$errors.length ? 'mb-1' : ''">
                <label for="birthdate" :class="{'p-error':v$.localIndependent.profil.birthDate.$errors.length}">
                  {{ $t('birthDate') }} *
                </label>
                <Calendar id="birthdate" v-model="v$.localIndependent.profil.birthDate.$model"
                          :disabled="me.role.name === constants.ROLES.INDEPENDENT && isIndependentContractValidated" :showIcon="true"
                          date-format="dd/mm/yy"
                          :class="{'p-invalid':v$.localIndependent.profil.birthDate.$errors.length}"
                          @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.profil.birthDate.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.birthDate.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div
                  class="field mb-4 col-12 md:col-6"
                  :class="v$.localIndependent.socialSecurityCode.$dirty && v$.localIndependent.socialSecurityCode.$errors.length ? 'mb-1' : ''"
              >
                <label for="social-security-code"
                       :class="{'p-error':v$.localIndependent.socialSecurityCode.$errors.length}">
                  {{ $t('socialSecurityNumber') }} *
                </label>
                <InputMask id="social-security-code" v-model="v$.localIndependent.socialSecurityCode.$model"
                           :class="{'p-invalid':v$.localIndependent.socialSecurityCode.$errors.length}"
                           mask="9 99 99 99 999 999 ?99" unmask @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.socialSecurityCode.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.socialSecurityCode.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div class="field mb-4 col-12 md:col-12"
                   :class="v$.localIndependent.profil.nationality.$dirty && v$.localIndependent.profil.nationality.$errors.length ? 'mb-1' : ''">
                <label :class="{'p-error':v$.localIndependent.profil.nationality.$errors.length}">
                  {{ $t('nationality') }} *
                </label>
                <div class="flex justify-content-evenly">
                  <div id="nationality-default" class="flex gap-1 align-items-center">
                    <label for="nationality-local">{{ $t('frenchF') }}</label>
                    <RadioButton id="nationality-local" v-model="v$.localIndependent.profil.nationality.$model"
                                 :value="constants.NATIONALITY_TYPE.FRENCH"
                                 :class="{'p-invalid':v$.localIndependent.profil.nationality.$errors.length}"
                                 @click="nationalityUpdated" @update:modelValue="changeProfileFields"/>
                  </div>
                  <div id="nationality-eu" class="flex gap-1 align-items-center">
                    <label for="nationality-foreigner">{{ $t('europe') }}</label>
                    <RadioButton id="nationality-foreigner" v-model="v$.localIndependent.profil.nationality.$model"
                                 :value="constants.NATIONALITY_TYPE.EUROPEAN_UNION"
                                 :class="{'p-invalid':v$.localIndependent.profil.nationality.$errors.length}"
                                 @click="nationalityUpdated" @update:modelValue="changeProfileFields"/>
                  </div>
                  <div id="nationality-eu-out" class="flex gap-1 align-items-center">
                    <label for="nationality-eu-out">{{ $t('europeOut') }}</label>
                    <RadioButton id="nationality-eu-out" v-model="v$.localIndependent.profil.nationality.$model"
                                 :value="constants.NATIONALITY_TYPE.EUROPEAN_UNION_OUT"
                                 :class="{'p-invalid':v$.localIndependent.profil.nationality.$errors.length}"
                                 @click="nationalityUpdated" @update:modelValue="changeProfileFields"/>
                  </div>
                </div>
              </div>
              <div v-if="v$.localIndependent.profil.nationality.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.nationality.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <!--  todo: Manque des infos sur comment traiter le problème dans le doc des observations  -->
              <div v-if="localIndependent?.profil?.nationality === constants.NATIONALITY_TYPE.EUROPEAN_UNION"
                  :class="v$.localIndependent.profil.isPassport.$dirty && v$.localIndependent.profil.isPassport.$errors.length ? 'mb-1' : ''"
                   class="field mb-4 col-12 md:col-6">
                <label :class="{'p-error':v$.localIndependent.profil.isPassport.$errors.length}">
                  {{ $t('isPassportQuestion') }} *
                </label>
                <div class="flex justify-content-evenly">
                  <div class="flex gap-1 align-items-center">
                    <label for="is-passport-yes">{{ $t('isPassport') }}</label>
                    <RadioButton id="is-passport-yes" v-model="v$.localIndependent.profil.isPassport.$model"
                                 :value="1"
                                 :class="{'p-invalid':v$.localIndependent.profil.isPassport.$errors.length}"
                                 @click="isPassportUpdated" @update:modelValue="changeProfileFields"/>
                  </div>
                  <div class="flex gap-1 align-items-center">
                    <label for="is-passport-no">{{ $t('isNoPassport') }}</label>
                    <RadioButton id="is-passport-no" v-model="v$.localIndependent.profil.isPassport.$model" :value="0"
                                 :class="{'p-invalid':v$.localIndependent.profil.isPassport.$errors.length}"
                                 @click="isPassportUpdated" @update:modelValue="changeProfileFields"/>
                  </div>
                </div>
              </div>
              <div v-if="v$.localIndependent.profil.isPassport.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.isPassport.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div
                  v-if="localIndependent.profil.nationality === constants.NATIONALITY_TYPE.EUROPEAN_UNION && localIndependent.profil.isPassport && localIndependent.profil.isPassport === 1"
                  class="field mb-4 col-12 md:col-6"
                  :class="v$.localIndependent.profil.passport.id.$dirty && v$.localIndependent.profil.passport.id.$errors.length ? 'mb-1' : ''"
              >
                <label for="passport-number"
                       :class="{'p-error':v$.localIndependent.profil.passport.id.$errors.length}">
                  {{ $t('passportNumber') }} *
                </label>
                <InputText id="passport-number" type="text" v-model="localIndependent.profil.passport.id"
                           :class="{'p-invalid':v$.localIndependent.profil.passport.id.$errors.length}"
                           @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.profil.passport.id.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.passport.id.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div
                  v-if="localIndependent.profil.nationality === constants.NATIONALITY_TYPE.EUROPEAN_UNION && localIndependent.profil.isPassport && localIndependent.profil.isPassport === 1"
                  class="field mb-4 col-12 md:col-6"
                  :class="v$.localIndependent.profil.passport.expirationDate.$dirty && v$.localIndependent.profil.passport.expirationDate.$errors.length ? 'mb-1' : ''"
              >
                <label for="passport-expiration-date"
                       :class="{'p-error':v$.localIndependent.profil.passport.expirationDate.$errors.length}">
                  {{ $t('expirationDate') }} *
                </label>
                <Calendar id="passport-expiration-date" :showIcon="true"
                          v-model="localIndependent.profil.passport.expirationDate" date-format="dd/mm/yy"
                          :class="{'p-invalid':v$.localIndependent.profil.passport.expirationDate.$errors.length}"
                          @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.profil.passport.expirationDate.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.passport.expirationDate.$errors"
                        :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div
                  v-if="localIndependent?.profil?.nationality === constants.NATIONALITY_TYPE.EUROPEAN_UNION_OUT
                  || (localIndependent?.profil?.nationality === constants.NATIONALITY_TYPE.EUROPEAN_UNION
                  && localIndependent?.profil?.isPassport !== null && localIndependent?.profil?.isPassport === 0)"
                  class="field mb-4 col-12 md:col-6"
                  :class="v$.localIndependent.profil.residencePermit.id.$dirty && v$.localIndependent.profil.residencePermit.id.$errors.length ? 'mb-1' : ''"
              >
                <label for="residence-permit"
                       :class="{'p-error':v$.localIndependent.profil.residencePermit.id.$errors.length}">
                  {{ $t('residencePermit') }} *
                </label>
                <InputText id="residence-permit" type="text"
                           v-model="v$.localIndependent.profil.residencePermit.id.$model"
                           :class="{'p-invalid':v$.localIndependent.profil.residencePermit.id.$errors.length}"
                           @update:modelValue="changeProfileFields"/>
              </div>
              <div
                  v-if="(localIndependent.profil.nationality === constants.NATIONALITY_TYPE.EUROPEAN_UNION  &&  localIndependent.profil.isPassport === 0) || localIndependent.profil.nationality === constants.NATIONALITY_TYPE.EUROPEAN_UNION_OUT"
                  class="field mb-4 col-12 md:col-6"
                  :class="v$.localIndependent.profil.residencePermit.expirationDate.$dirty && v$.localIndependent.profil.residencePermit.expirationDate.$errors.length ? 'mb-1' : ''"
              >
                <label for="passport-expiration-date"
                       :class="{'p-error':v$.localIndependent.profil.residencePermit.expirationDate.$errors.length}">
                  {{ $t('expirationDate') }} *
                </label>
                <Calendar id="passport-expiration-date" :showIcon="true"
                          v-model="localIndependent.profil.residencePermit.expirationDate" date-format="dd/mm/yy"
                          :class="{'p-invalid':v$.localIndependent.profil.residencePermit.expirationDate.$errors.length}"
                          @update:modelValue="changeProfileFields"/>
              </div>
              <div
                  v-if="localIndependent?.profil?.nationality === constants.NATIONALITY_TYPE.EUROPEAN_UNION_OUT
                  || localIndependent?.profil?.nationality === constants.NATIONALITY_TYPE.EUROPEAN_UNION"
                  class="field mb-4 col-12 md:col-6"
                  :class="v$.localIndependent.profil.originalCountry.$dirty && v$.localIndependent.profil.originalCountry.$errors.length ? 'mb-1' : ''"
              >
                <label for="country-origin"
                       :class="{'p-error':v$.localIndependent.profil.originalCountry.$errors.length}">
                  {{ $t('countryOrigin') }} *
                </label>
                <InputText id="country-origin" type="text"
                           v-model="v$.localIndependent.profil.originalCountry.$model"
                           :class="{'p-invalid':v$.localIndependent.profil.originalCountry.$errors.length}"
                           @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.profil.originalCountry.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.originalCountry.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div class="field mb-4 col-12 md:col-6"
                   :class="v$.localIndependent.profil.personalSituation.$dirty && v$.localIndependent.profil.personalSituation.$errors.length ? 'mb-1' : ''">
                <label for="personal-situation"
                       :class="{'p-error':v$.localIndependent.profil.personalSituation.$errors.length}">
                  {{ $t('personalSituation') }} *
                </label>
                <Dropdown id="personal-situation" v-model="v$.localIndependent.profil.personalSituation.$model"
                          option-label="name" option-value="value" :options="constants.data.personalSituationOptions"
                          :class="{'p-invalid':v$.localIndependent.profil.personalSituation.$errors.length}"
                          @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.profil.personalSituation.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.personalSituation.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="child-situation">
                  {{ $t('childSituation') }}
                </label>
                <Textarea v-model="localIndependent.profil.childSituation" style="border-radius: 10px"
                          :autoResize="true" rows="1" @update:modelValue="changeProfileFields"/>
              </div>
              <div class="field mb-4 col-12 md:col-6"
                   :class="v$.localIndependent.profil.handicap.exist.$dirty && v$.localIndependent.profil.handicap.exist.$errors.length ? 'mb-1' : ''">
                <label :class="{'p-error':v$.localIndependent.profil.handicap.exist.$errors.length}">
                  {{ $t('handicapQuestion') }} *
                </label>
                <div class="flex justify-content-evenly">
                  <div class="flex gap-1 align-items-center">
                    <label for="handicap-yes">{{ $t('isHandicap') }}</label>
                    <RadioButton id="handicap-yes" v-model="v$.localIndependent.profil.handicap.exist.$model"
                                 :value="1"
                                 :class="{'p-invalid':v$.localIndependent.profil.handicap.exist.$errors.length}"
                                 @update:modelValue="changeProfileFields"/>
                  </div>
                  <div class="flex gap-1 align-items-center">
                    <label for="handicap-no">{{ $t('isNoHandicap') }}</label>
                    <RadioButton id="handicap-no" v-model="v$.localIndependent.profil.handicap.exist.$model"
                                 :value="0"
                                 :class="{'p-invalid':v$.localIndependent.profil.handicap.exist.$errors.length}"
                                 @update:modelValue="changeProfileFields"/>
                  </div>
                </div>
              </div>
              <div v-if="v$.localIndependent.profil.handicap.exist.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.handicap.exist.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div

                  class="field mb-4 col-12 md:col-6"
                  :class="v$.localIndependent.profil.handicap.details.$dirty && v$.localIndependent.profil.handicap.details.$errors.length ? 'mb-1' : ''"
              >
                <label for="handicap-details"
                       v-if="localIndependent?.profil?.handicap?.exist"
                       :class="{'p-error':v$.localIndependent.profil.handicap.details.$errors.length}">
                  Si oui, lesquels ?
                </label>
                <InputText id="handicap-details" type="text" v-if="localIndependent?.profil?.handicap?.exist"
                           v-model="v$.localIndependent.profil.handicap.details.$model"
                           :class="{'p-invalid':v$.localIndependent.profil.handicap.details.$errors.length}"
                           @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.profil.handicap.details.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.handicap.details.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div
                  class="field mb-4 col-12 md:col-6"
                  :class="v$.localIndependent.profil.medicalVisit.$dirty && v$.localIndependent.profil.medicalVisit.$errors.length ? 'mb-1' : ''"
              >
                <label for="medical-visit"
                       :class="{'p-error':v$.localIndependent.profil.medicalVisit.$errors.length}">
                  {{ $t('medicalVisit') }}
                </label>
                <Dropdown id="medical-visit" v-model="v$.localIndependent.profil.medicalVisit.$model"
                          :options="constants.data.medicalVisitOptions" option-label="name" option-value="value"
                          :class="{'p-invalid':v$.localIndependent.profil.medicalVisit.$errors.length}"
                          @update:modelValue="changeProfileFields"/>
              </div>
              <div class="field mb-4 col-12 md:col-6" :class="v$.localIndependent.profil.medicalVisitDate.$dirty && v$.localIndependent.profil.medicalVisitDate.$errors.length ? 'mb-1' : ''">
                <label for="medical-visit-date"
                       :class="{'p-error':v$.localIndependent.profil.medicalVisitDate.$errors.length}">
                  {{ $t('medicalVisitDate') }}
                </label>
                <Calendar id="medical-visit-date" v-model="v$.localIndependent.profil.medicalVisitDate.$model"
                          showIcon date-format="dd/mm/yy" @update:modelValue="changeProfileFields"/>
              </div>
              <div v-if="v$.localIndependent.profil.medicalVisitDate.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.medicalVisitDate.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="mutuelle">Quelle mutuelle souhaitez-vous ?</label>
                <Dropdown id="mutuelle" v-model="localIndependent.profil.mutuelle" option-label="name"
                          option-value="value" :options="constants.data.mutualOptions"
                          @update:modelValue="changeProfileFields"/>
              </div>
            </div>
          </template>
        </Card>
        <OuterCardTitle v-if="me.role.name === constants.ROLES.PROSPECT" :value="$t('contract')"/>
        <Card v-if="me.role.name === constants.ROLES.PROSPECT">
          <template #content>
            <div class="grid formgrid p-fluid">
              <div class="field mb-4 col-12 md:col-3">
                <span>{{ $t('workContract') }}</span>
              </div>
              <div class="field mb-4 col-12 md:col-9">
                <span class="text-lg text-900 font-medium">{{ localIndependent?.profil?.workContract?.contract?.toLocaleUpperCase() }}</span>
              </div>
              <div class="field mb-4 col-12 md:col-3">
                <span>{{ $t('managementFee') }}</span>
              </div>
              <div class="field mb-4 col-12 md:col-9">
                <span class="text-lg text-900 font-medium">{{ localIndependent?.profil?.workContract?.managementCost }} %</span>
              </div>
              <div v-if="typeof simulation?.jsonMissions?.managementFee?.cap === 'number'" class="field mb-3 col-12 md:col-3">
                <span>{{ $t('managementRateRoofLabel') }}</span>
              </div>
              <div v-if="typeof simulation?.jsonMissions?.managementFee?.cap === 'number'" class="field mb-3 col-12 md:col-9">
                <span class="text-lg text-900 font-medium">{{ simulation?.jsonMissions?.managementFee?.cap }} €</span>
              </div>
              <div class="field mb-4 col-12 md:col-12"
                  :class="v$.localIndependent.profil.workContract.ibanSalary.$dirty && v$.localIndependent.profil.workContract.ibanSalary.$errors.length ? 'mb-1' : ''">
                <label for="prospect-contract-iban-salary" :class="{'p-error':v$.localIndependent.profil.workContract.ibanSalary.$errors.length}">
                  {{ `${$t('ibanSalaryInput')} *` }}
                </label>
                <InputText id="prospect-contract-iban-salary"
                           v-model="v$.localIndependent.profil.workContract.ibanSalary.$model" option-label="name"
                           :class="{'p-invalid':v$.localIndependent.profil.workContract.ibanSalary.$errors.length}"/>
              </div>
              <div v-if="v$.localIndependent.profil.workContract.ibanSalary.$errors.length" class="mb-4 col-12">
                <span v-for="(error, index) of v$.localIndependent.profil.workContract.ibanSalary.$errors"
                      :key="index">
                  <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                </span>
              </div>
              <div class="flex flex-row mb-4 col-12 md:col-12 gap-2 align-items-center">
                <label for="prospect-iban-ndf-is-different">Si différent de IBAN-Salaire</label>
                <Checkbox id="prospect-iban-ndf-is-different" v-model="v$.ibanNdfDifferent.$model" type="text" binary
                          @change="localIndependent.profil.workContract.ibanNdf = localIndependent.profil.workContract.ibanSalary"/>
              </div>
              <div class="field mb-4 col-12 md:col-12"
                  :class="v$.localIndependent.profil.workContract.ibanNdf.$dirty && v$.localIndependent.profil.workContract.ibanNdf.$errors.length ? 'mb-1' : ''">
                <label for="prospect-contract-iban-ndf"
                       :class="{'p-error':v$.localIndependent.profil.workContract.ibanNdf.$errors.length}">
                  {{ $t('ibanNdfInput') }}
                </label>
                <InputText id="prospect-contract-iban-ndf" option-label="name"
                           v-model="v$.localIndependent.profil.workContract.ibanNdf.$model"
                           :disabled="!ibanNdfDifferent"
                           :class="{'p-invalid':v$.localIndependent.profil.workContract.ibanNdf.$errors.length}"/>
              </div>
              <div v-if="v$.localIndependent.profil.workContract.ibanNdf.$errors.length" class="mb-4 col-12">
                <span v-for="(error, index) of v$.localIndependent.profil.workContract.ibanNdf.$errors"
                      :key="index">
                  <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                </span>
              </div>
            </div>
          </template>
        </Card>
        <Card v-if="me.role.name === constants.ROLES.PROSPECT">
          <template #content>
            <div class="grid formgrid p-fluid">
              <div class="field mb-4 col-12">
                <label for="jobPosition" class="text-sm font-bold line-height-3 mb-2"
                    :class="{'p-error':v$.localIndependent.profil.job.$errors.length}">
                  {{ $t('jobPosition') }} * :
                </label>
                <InputText id="jobPosition" v-model="v$.localIndependent.profil.job.$model" class="mb-1"
                           style="width: 100%; border-radius: 10px"
                           :class="{'p-invalid':v$.localIndependent.profil.job.$errors.length}"/>
              </div>
              <div class="field mb-4 col-12">
                <div v-if="v$.localIndependent.profil.job.$errors.length" class="mb-4 col-12">
                  <span v-for="(error, index) of v$.localIndependent.profil.job.$errors" :key="index">
                    <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                  </span>
                </div>
              </div>
              <div class="field mb-4 col-12">
                <p class="m-0 mb-2 p-0 text-sm font-bold line-height-3 mr-3">{{ $t('skillsDetails') }} :</p>
                <Textarea v-model="localIndependent.competencesDetails" style="width: 100%; border-radius: 10px"
                          :autoResize="true" rows="5"/>
              </div>
              <div class="field mb-4 col-12">
                <label
                    class="mb-2 text-sm font-bold line-height-3"
                    :class="{'p-error': v$.localIndependent.profil.source.type.$errors.length || v$.localIndependent.profil.source.value.$errors.length}"
                >
                  Comment nous connaissez-vous ? *
                </label>
                <div
                    class="field mb-0 flex justify-content-between gap-2"
                    :class="(v$.localIndependent.profil.source.type.$dirty && v$.localIndependent.profil.source.type.$errors.length)
                || (v$.localIndependent.profil.source.value.$dirty && v$.localIndependent.profil.source.value.$errors.length)
               ? 'mb-1' : ''"
                >
                  <Dropdown v-model="v$.localIndependent.profil.source.type.$model"
                            :options="settings?.Commercial?.sources"
                            :class="{'p-invalid':v$.localIndependent.profil.source.type.$errors.length}"/>
                  <InputText type="text" v-model="v$.localIndependent.profil.source.value.$model"
                             :class="{'p-invalid':v$.localIndependent.profil.source.value.$errors.length}"/>
                </div>
              </div>
              <div class="field mb-4 col-12">
                <div v-if="v$.localIndependent.profil.source.type.$errors.length || v$.localIndependent.profil.source.value.$errors.length"
                    class="mb-4 col-12">
                  <span v-for="(error, index) of [...v$.localIndependent.profil.source.type.$errors, ...v$.localIndependent.profil.source.value.$errors]"
                        :key="index">
                    <InlineMessage class="w-full mb-1">
                      {{ error.$message }}
                    </InlineMessage>
                  </span>
                </div>
              </div>
            </div>
          </template>
        </Card>
        <Card v-if="me.role.name === constants.ROLES.ADMIN || me.role.name === constants.ROLES.INDEPENDENT">
          <template #content>
            <div class="field mb-0">
              <Checkbox :binary="true" id="credit-card" type="text" v-model="localIndependent.credit_card"
                        :disabled="me.role.name === constants.ROLES.INDEPENDENT" @change="changeCreditCardOption"/>
              <span class="ml-2" for="credit-card">Carte bancaire</span>
            </div>
            <!--                <div v-if="localIndependent.credit_card" class="field">-->
            <!--                  <label for="credit-card-fee">Frais de carte bancaire</label>-->
            <!--                  <InputNumber id="credit-card-fee" type="text" v-model="localIndependent.credit_card_fee" :min="0" :disabled="me.role.name === constants.ROLES.INDEPENDENT" locale="fr-FR" mode="currency" currency="EUR" />-->
            <!--                </div>-->
          </template>
        </Card>
        <Card v-if="me.role.name === constants.ROLES.ADMIN || me.role.name === constants.ROLES.INDEPENDENT">
          <template #content>
            <p class="m-0 mb-2 p-0 line-height-3 mr-3">{{ $t('jobPosition') }} :</p>
            <InputText v-model="localIndependent.profil.job" class="mb-3" style="width: 100%; border-radius: 10px"
                       @update:modelValue="changeProfileFields"/>

            <p class="m-0 mb-2 p-0 line-height-3 mr-3">Compétences et qualifications</p>
            <Textarea style="width: 100%; border-radius: 10px" :autoResize="true" rows="5"
                      v-model="localIndependent.competencesDetails"
                      @update:modelValue="changeProfileFields"/>
          </template>
        </Card>
        <div class="spacer"></div>
      </div>

      <div v-if="me.role.name === constants.ROLES.ADMIN || me.role.name === constants.ROLES.INDEPENDENT"
           class="flex flex-column right-cards cards gap-3">
        <OuterCardTitle value="Contrat de travail"/>
        <Card class="w-full">
          <template #content>
            <div class="grid formgrid p-fluid">
              <div class="field mb-4 col-12 md:col-12">
                <label for="contractStartingDate">Date de démarrage</label>
                <Calendar id="contractStartingDate" v-model="localIndependent.profil.workContract.startingDate"
                          :disabled="me.role.name === constants.ROLES.INDEPENDENT || isIndependentContractValidated"
                          showIcon date-format="dd/mm/yy" @update:modelValue="changeProfileFields"/>
              </div>
            <div class="field mb-4 col-12 md:col-6">
              <label for="work-contract">Type de contrat</label>
              <!--                  <InputText id="work-contract" type="text" v-model="localIndependent.profil.workContract.contract" :disabled="me.role.name === constants.ROLES.INDEPENDENT"/>-->
              <Dropdown id="work-contract" v-model="localIndependent.profil.workContract.contract"
                        option-label="name" option-value="value" :options="constants.data.contractOption"
                        :disabled="me.role.name === constants.ROLES.INDEPENDENT"
                        @update:modelValue="changeProfileFields"/>
            </div>

            <div v-if="localIndependent?.profil?.workContract?.contract === constants.WORK_CONTRACT_TYPE.CDD" class="field mb-4 col-12 md:col-6">
              <label for="contractEndingDate">Date de fin</label>
              <Calendar id="contractEndingDate" v-model="localIndependent.profil.workContract.endingDate"
                        :disabled="me.role.name === constants.ROLES.INDEPENDENT" showIcon
                        date-format="dd/mm/yy" @update:modelValue="changeProfileFields"/>
            </div>
            <div class="field mb-4 col-12 md:col-12">
              <label for="contract-iban-salary">{{ $t('ibanSalaryInput') }}</label>
              <InputText id="contract-iban-salary" option-label="name"
                         v-model="localIndependent.profil.workContract.ibanSalary"
                         :disabled="me.role.name === constants.ROLES.INDEPENDENT"
                         @update:modelValue="changeProfileFields"/>
            </div>
            <div class="field mb-4 col-12 md:col-12">
              <label for="contract-iban-ndf">{{ $t('ibanNdfInput') }}</label>
              <InputText id="contract-iban-ndf" option-label="name"
                         v-model="localIndependent.profil.workContract.ibanNdf"
                         :disabled="me.role.name === constants.ROLES.INDEPENDENT"
                         @update:modelValue="changeProfileFields"/>
            </div>


            <div class="field mb-4 col-12 md:col-12">
              <label for="managementFee">Tarif de gestion</label>
              <div v-if="missionsCompletes?.length > 0" class="gap-2">
                <Chip v-for="(mission, index) in missionsCompletes" :key="index"
                      :label="getChipMissionLabel(mission)" style="cursor: pointer"
                      class="hover:bg-primary transition-duration-100"
                      @click="openMissionManagementFees(mission)"
                />

              </div>
            </div>
            <!--                todo: Besoin du salaire de base dans le mode prospect ?-->
            <div class="field mb-4 col-12 md:col-6">
              <label for="base-salary">Salaire de base</label>
              <InputNumber id="base-salary" type="text" v-model="localIndependent.profil.workContract.baseSalary"
                           :disabled="me.role.name === constants.ROLES.INDEPENDENT" :min="0" locale="fr-FR"
                           mode="currency" currency="EUR" @update:modelValue="changeProfileFields"/>
            </div>
            <div class="field mb-4 col-12 md:col-6">
              <label for="convention-collective">Convention</label>
              <InputText id="convention-collective" type="text" v-model="settings.Entreprise.convention" disabled/>
            </div>
            <div v-if="settings.Entreprise.convention === constants.COLLECTIVE_CONVENTION.SYNTEC" class="field mb-4 col-12 md:col-6">
              <label for="position-coefficient">Position / Coefficient</label>
              <AutoComplete
                  id="position-coefficient"
                  v-model="positionCoefficient"
                  placeholder="Position / Coefficient"
                  class="p-fluid h-auto"
                  input-class="h-auto"
                  dropdown-class="h-auto"
                  :field="(value) => `${value.position} / ${value.coefficient} / ${value.minimumBrutSalary}`"
                  :suggestions="syntecPositionsCoefficients"
                  :disabled="me.role.name === constants.ROLES.INDEPENDENT"
                  dropdown
                  force-selection
                  @complete="searchPositionCoefficient($event)"
                  @item-select="(event) => selectPositionCoefficient(event.value)"
                  @update:modelValue="changeProfileFields"
              >
                <template #item="slotProps">
                  <div class="flex flex-column">
                    <p>{{
                        `${slotProps?.item?.position} / ${slotProps?.item?.coefficient} / ${slotProps?.item?.minimumBrutSalary}`
                      }}</p>
                  </div>
                </template>
              </AutoComplete>
            </div>
              <div v-if="settings.Entreprise.convention === constants.COLLECTIVE_CONVENTION.PORTAGE" class="field">
                <label for="portage-status">Statut</label>
                <Dropdown
                    id="portage-status" v-model="localIndependent.profil.workContract.status"
                    :option-label="(val) => `${val.type}`" :options="formatFinancialReservesToArray"
                    :disabled="me.role.name === constants.ROLES.INDEPENDENT" @update:modelValue="changeProfileFields"
                >
                  <template #value="slotProps">
                  <span :style="!slotProps?.value?.type ? 'visibility: hidden' : ''">
                    {{ slotProps?.value?.type ?? 'p-emptylabel' }}
                  </span>
                  </template>
                </Dropdown>
              </div>
            <div v-if="settings.Entreprise.convention === constants.COLLECTIVE_CONVENTION.SYNTEC" class="field mb-4 col-12 md:col-6">
              <label for="syntec-status">Statut</label>
              <Dropdown
                  id="syntec-status" v-model="localIndependent.profil.workContract.status"
                  option-label="label" option-value="value" :options="constants.data.SYNTEC_STATUS"
                  :disabled="me.role.name === constants.ROLES.INDEPENDENT" @update:modelValue="changeProfileFields"/>
            </div>

            <div class="field mb-4 col-12 md:col-12">
              <label for="trial-period">Période d'essai</label>
              <InputNumber
                  id="trial-period" v-model="localIndependent.profil.workContract.trialPeriod"
                  :min="0" type="text" :suffix="getTrialPeriodSuffix" :disabled="me.role.name === constants.ROLES.INDEPENDENT"
                  @update:modelValue="changeProfileFields"/>
            </div>
            <div v-if="localIndependent?.profil?.workContract?.workTime" class="field mb-4 col-12 md:col-6">
              <label for="partial-time">Temps partiel</label>
              <div class="flex flex row ">
                <RadioButton v-model="localIndependent.profil.workContract.workTime.type" class="mr-7 mt-2"
                             :value="constants.WORK_TIME.PARTIAL" :disabled="me.role.name === constants.ROLES.INDEPENDENT"
                             @update:modelValue="changeProfileFields"/>
                <div v-if="localIndependent.profil.workContract.workTime.type === constants.WORK_TIME.PARTIAL"
                     class="flex flex-column">
                  <InputNumber v-model="localIndependent.profil.workContract.workTime.value" class="mb-2"
                               suffix=" h" :disabled="me.role.name === constants.ROLES.INDEPENDENT"
                               @update:modelValue="changeProfileFields"/>
                  <InputNumber v-model="localIndependent.profil.workContract.workTime.costValue" class="mb-2"
                               suffix=" € / h" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"
                               :disabled="me.role.name === constants.ROLES.INDEPENDENT"
                               @update:modelValue="changeProfileFields"/>
                  <Textarea v-model="localIndependent.profil.workContract.workTime.description" rows="3" cols="30"
                            :disabled="me.role.name === constants.ROLES.INDEPENDENT"
                            @update:modelValue="changeProfileFields"/>
                </div>
              </div>
            </div>
            <div v-if="localIndependent?.profil?.workContract?.workTime" class="field mb-4 col-12 md:col-6">
              <label for="full-time">Temps plein</label>
              <div class="flex flex row ">
                <RadioButton v-model="localIndependent.profil.workContract.workTime.type" class="mr-7 mt-2"
                             :value="constants.WORK_TIME.FULL" :disabled="me.role.name === constants.ROLES.INDEPENDENT"
                             @update:modelValue="changeProfileFields"/>
                <div class="flex flex-column">
                  <InputNumber v-model="settings.Paie.workTime.workingHoursOnFullTime" suffix=" h" disabled/>
                </div>
              </div>
            </div>
            <div class="field mb-4 col-12 md:col-3">
                <Checkbox id="teleworking" v-model="localIndependent.profil.workContract.teleworking"
                          :disabled="me.role.name === constants.ROLES.INDEPENDENT" binary type="text"
                          @input="changeTeleworking" @update:modelValue="changeProfileFields"/>
                <span for="teleworking" class="ml-2">Télétravail</span>
            </div>
            <div class="field mb-4 col-12 md:col-9">
                <Textarea v-if="localIndependent.profil.workContract.teleworking"
                          v-model="localIndependent.profil.workContract.teleworkingDescription" rows="4" cols="40"
                          :disabled="me.role.name === constants.ROLES.INDEPENDENT" @update:modelValue="changeProfileFields"/>
            </div>
            <div v-if="localIndependent.restaurants_tickets || settings.RH.ticketsRestaurant.value"
                class="field mb-4 col-12 md:col-12">
              <div class="flex flex-row align-items-center">
                <Checkbox id="restaurants-tickets" v-model="localIndependent.restaurants_tickets"
                          :disabled="me.role.name === constants.ROLES.INDEPENDENT" binary type="text"
                          :class="localIndependent.restaurants_tickets ? '' : 'mr-2'"
                          @update:modelValue="changeProfileFields"/>
                <span class="ml-2" for="restaurants-tickets">Ticket restaurant</span>
                <Button v-if="!localIndependent.restaurants_tickets" label="Envoyer lettre de renonciation"
                        class="p-button-rounded p-button-sm ml-3 w-4" @click="sendRestaurantTicketsRenunciationLetter"/>
              </div>
            </div>
            </div>
          </template>
        </Card>
        <template  v-if="me.role.name === constants.ROLES.ADMIN">
          <ApplicableConditions
              :existing-applicable-conditions="applicableConditionsFromIndependentMissions"
              @updateApplicableConditions="updateOtherAppliedConditions"
              class="w-full"
          />
        </template>
      </div>
    </div>
    <dialog-confirm
        :is-active="confirmDialog"
        :refuse-action="confirmDialogRefuse"
        :validate-action="confirmDialogValidate"
        :title="confirmDialogTitle"
        :text-info="confirmDialogInfo"
        :text-question="confirmDialogQuestion"
    />
    <dialog-credit-card-supplier-invoice
        :is-active="dialogCreditCardSupplierInvoiceIsActive"
        :defaultCreditCardFee="dialogCreditCardSupplierInvoiceFee"
        :independent="dialogCreditCardSupplierInvoiceIndependent"
        @closeDialog="closeDialogCreditCardSupplierInvoice"
    />
    <dialog-management-fee-values v-if="me.role.name === constants.ROLES.ADMIN && missionSelected" :mission="missionSelected"
                                  :is-active="openManagementFeeValues" disabled
                                  @closeDialog="closeManagementFeeValuesDialog"/>
  </div>
</template>

<script>
import Alert from '@/utils/Alert'
import {mapState} from "vuex";
import {formatFinancialReserveToArray} from "@/utils/RhUtil";
import DialogConfirm from "@/components/DialogConfirm";
import DialogCreditCardSupplierInvoice from "@/components/RH/DialogCreditCardSupplierInvoice";
import * as writtenNumber from 'written-number'
import {isValidIBANNumber} from "@/utils/IbanUtils";
import {minLength, helpers, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {getLastValuePassed} from "@/utils/Misc";
import {setDateWithoutHours} from "@/utils/Date";
import DialogManagementFeeValues from "@/components/DialogManagementFeeValues.vue";
import OuterCardTitle from "@/components/OuterCardTitle";
import ApplicableConditions from "@/components/ApplicableConditions.vue";

export default {
  name: "Profil",
  setup: () => ({v$: useVuelidate()}),
  emits: ['canValidated', 'onProfileFieldChange'],
  // ApplicableConditions
  components: {
    ApplicableConditions,
    OuterCardTitle, DialogManagementFeeValues, DialogCreditCardSupplierInvoice, DialogConfirm},
  props: ['independent', 'step', 'viewRH'],
  data() {
    return {
      // this.independent
      localIndependent: this.independent,
      positionCoefficient: {},
      syntecPositionsCoefficients: [],
      applicableConditionsFromIndependentMissions: [],
      ibanNdfDifferent: false,

      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogInfo: '',
      confirmDialogQuestion: '',
      confirmDialogValidate: () => {
      },
      confirmDialogRefuse: () => {
      },

      dialogCreditCardSupplierInvoiceIsActive: false,
      dialogCreditCardSupplierInvoiceFee: 0,
      dialogCreditCardSupplierInvoiceIndependent: {},

      ibanSalaryValidated: false,
      ibanNdfValidated: false,
      missionsCompletes: [],

      missionSelected: null,
      openManagementFeeValues: false,

      enableChangeProfileFieldsNotify: false,

      submitted: false
    }
  },
  validations() {
    const form = {
      ibanNdfDifferent: {}, // need to instantiate it for reactivity
      localIndependent: {
        // email: { // email défini lors de l'envoi de la simulation
        //   required: helpers.withMessage('Email obligatoire', required),
        //   email: helpers.withMessage('Votre email est invalide', email)
        // },
        first_name: {},
        last_name: {},
        phone: {},
        socialSecurityCode: {},
        profil: {
          civilStatus: {},
          address: {},
          zipCode: {},
          city: {},
          country: {},
          birthPlace: {},
          birthDate: {},
          nationality: {},
          originalCountry: {},
          isPassport: {},
          passport: {
            id: {},
            expirationDate: {}
          },
          residencePermit: {
            id: {},
            expirationDate: {}
          },
          personalSituation: {},
          childSituation: {},
          handicap: {
            exist: {},
            details: {}, // no specific old validations
          },
          medicalVisit: {},
          medicalVisitDate: {},
          workContract: {
            ibanSalary: {},
            ibanNdf: {}
          },
          job: {},
          source: {
            type: {},
            value: {},
          }
        }
      }
    }

    if (this.me.role.name === this.constants.ROLES.PROSPECT) {
      form.localIndependent.first_name = {
        required: helpers.withMessage('Prénom obligatoire', required),
        minLength: helpers.withMessage('Votre prénom doit contenir au deux caractères', minLength(2))
      }
      form.localIndependent.first_name = {
        required: helpers.withMessage('Nom obligatoire', required),
        minLength: helpers.withMessage('Votre nom doit contenir au deux caractères', minLength(2))
      }
      form.localIndependent.phone = { required: helpers.withMessage('Téléphone obligatoire', required) }

      form.localIndependent.profil.civilStatus = { required: helpers.withMessage('Civilité obligatoire', required) }
      form.localIndependent.profil.address = { required: helpers.withMessage('Adresse obligatoire', required) }
      form.localIndependent.profil.zipCode = { required: helpers.withMessage('Code postal obligatoire', required) }
      form.localIndependent.profil.city = { required: helpers.withMessage('Ville obligatoire', required) }
      form.localIndependent.profil.country = { required: helpers.withMessage('Pays obligatoire', required) }
      form.localIndependent.profil.birthPlace = { required: helpers.withMessage('Lieu de naissance obligatoire', required) }
      form.localIndependent.profil.birthDate = { required: helpers.withMessage('Date de naissance obligatoire', required) }
      form.localIndependent.profil.nationality = { required: helpers.withMessage('Nationalité obligatoire', required) }

      form.localIndependent.profil.personalSituation = { required: helpers.withMessage('Situation personnelle obligatoire', required) }

      form.localIndependent.profil.handicap.exist = { required: helpers.withMessage('Handicap obligatoire', required) }

      // form.localIndependent.profil.medicalVisit = { required: helpers.withMessage('Visite médical obligatoire', required) }
      // form.localIndependent.profil.medicalVisitDate = { required: helpers.withMessage('Date de la visite médical obligatoire', required) }

      form.localIndependent.profil.workContract.ibanSalary = {
        required: helpers.withMessage('Iban salaire obligatoire', required),
        isValidIBANNumber: helpers.withMessage('Iban salaire invalide', isValidIBANNumber)
      }

      form.localIndependent.profil.job = { required: helpers.withMessage('Poste et métier obligatoire', required) }

      form.localIndependent.profil.source.type = { required: helpers.withMessage('Type de la source obligatoire', required) }
      form.localIndependent.profil.source.value = { required: helpers.withMessage('Source obligatoire', required) }
    }


    if (this.localIndependent.profil.nationality === this.constants.NATIONALITY_TYPE.EUROPEAN_UNION) {
      form.localIndependent.profil.isPassport = {required: helpers.withMessage('Choix du type de document obligatoire', required)}
    }
    if (this.localIndependent.profil.nationality === this.constants.NATIONALITY_TYPE.EUROPEAN_UNION
        && this.localIndependent.profil.isPassport && this.localIndependent.profil.isPassport === 1) {
      form.localIndependent.profil.passport.id = {required: helpers.withMessage('Identifiant du passport obligatoire', required)}
      form.localIndependent.profil.passport.expirationDate = {required: helpers.withMessage('Date d\'expiration du passport obligatoire', required)}
    }
    if (this.localIndependent.profil.nationality === this.constants.NATIONALITY_TYPE.EUROPEAN_UNION_OUT
        || this.localIndependent.profil.nationality === this.constants.NATIONALITY_TYPE.EUROPEAN_UNION) {
      form.localIndependent.profil.originalCountry = {required: helpers.withMessage('Pays d\'origine obligatoire', required)}
    }
    if (this.localIndependent.profil.nationality === this.constants.NATIONALITY_TYPE.EUROPEAN_UNION_OUT
        || (this.localIndependent.profil.nationality === this.constants.NATIONALITY_TYPE.EUROPEAN_UNION
            && this.localIndependent.profil.isPassport !== null && this.localIndependent.profil.isPassport === 0)) {
      form.localIndependent.profil.residencePermit.id = {required: helpers.withMessage('Numéro de titre de séjour obligatoire', required)}
      form.localIndependent.profil.residencePermit.expirationDate = {required: helpers.withMessage('Date d\'expiration du passport obligatoire', required)}
    }
    form.localIndependent.socialSecurityCode = {
      required: helpers.withMessage('Numéro de sécurité social obligatoire', required),
    }
    if (this.ibanNdfDifferent) {
      form.localIndependent.profil.workContract.ibanNdf = {
        required: helpers.withMessage('Iban note de frais obligatoire', required),
        isValidIBANNumber: helpers.withMessage('Iban note de frais invalide', isValidIBANNumber)
      }
    }
    return form
  },
  computed: {
    ...mapState({
      settings: state => state.misc.settings,
      simulation: state => state.simulation.simulation,
      me: state => state.auth.me,
      constants: state => state.constants
    }),
    getTrialPeriodSuffix() {
      return ` (${writtenNumber(this.localIndependent.profil.workContract.trialPeriod, {lang: 'fr'})}) mois`
    },
    formatFinancialReservesToArray() {
      return formatFinancialReserveToArray(this.settings.Commercial.tauxReserveFinanciere)
    },
    isIndependentContractValidated () {
      return this.localIndependent.status === this.constants.INDEPENDENT_STATUS.NEW
    }
  },
  watch: {
    'independent': {
      async handler () {
        console.log('independent handler')
        this.enableChangeProfileFieldsNotify = false
        this.localIndependent = this.independent
        await this.initProfile()
        // todo: HOTFIX: not crazy -- issue -- changing the localIndep makes the InputMask getting @updateModelValue automatically
        setTimeout(() => {
          console.log('enableChangeProfileFieldsNotify to true')
          this.enableChangeProfileFieldsNotify = true
        }, 100)
      }
    },
    'localIndependent.profil.workContract.ibanSalary': {
      handler(value) {
        if (this.ibanNdfDifferent) {
          return
        }
        this.localIndependent.profil.workContract.ibanNdf = value
      },
      deep: true
    }
  },
  async created() {
    await this.initProfile()
  },
  methods: {
    getLastValuePassed,
    async initProfile () {
      if (this.me.role.name === this.constants.ROLES.PROSPECT) {
        await this.$store.dispatch('simulation/lastSharedSimulationByIndependent', {independentId: this.independent.id})
      }
      await this.initMissionComplete()
      await this.initFields()
      // todo: should define some validations
      this.$emit('canValidated', true)
    },
    initMissionComplete () {
      // todo: check if really need to request again the missions or just to loop through all the independent missions, await this.$store.dispatch('mission/getCompletedMissionsByIndependent', {id: this.localIndependent.id})
      this.missionsCompletes = this.localIndependent.missions
          .filter(mission =>
              mission.status === this.constants.MISSION_STATUS.PENDING_CONTRACT
              || mission.status === this.constants.MISSION_STATUS.COMPLETE
          )
    },
    async initFields() {
      if (!this.localIndependent.profil.workContract.workTime) {
        this.localIndependent.profil.workContract.teleworkingDescription = 'Le télétravail sera prévu en fonction des missions et des dispositions prévues par le client ou le client final'
      }
      if (this.settings.Entreprise.convention === this.constants.COLLECTIVE_CONVENTION.SYNTEC) {
        this.positionCoefficient = this.localIndependent.profil.workContract.positionCoefficient
      }
      if (!this.localIndependent.otherAppliedConditions && this.missionsCompletes?.length > 0) {
        const combinedMissionsApplicableConditions = []
        this.missionsCompletes?.forEach((mission) => {
          if (mission?.otherAppliedConditions) {
            combinedMissionsApplicableConditions.push(...mission?.otherAppliedConditions)
          }
        })
        this.applicableConditionsFromIndependentMissions = combinedMissionsApplicableConditions
        this.localIndependent.otherAppliedConditions = this.applicableConditionsFromIndependentMissions
      } else {
        this.applicableConditionsFromIndependentMissions = this.localIndependent.otherAppliedConditions
      }
      this.initIban()
    },
    initIban() {
      this.ibanNdfDifferent = this.localIndependent.profil.workContract.ibanSalary !== this.localIndependent.profil.workContract.ibanNdf
    },

    nationalityUpdated() {
      delete this.localIndependent.profil.isPassport
      this.localIndependent.profil.passport = {}
      this.localIndependent.profil.residencePermit = {}
    },
    isPassportUpdated() {
      if (this.localIndependent.profil.isPassport) {
        this.localIndependent.profil.residencePermit = {}
      } else {
        this.localIndependent.profil.passport = {}
      }
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate()
      console.log('isFormCorrect', isFormCorrect)
      if (!isFormCorrect) {
        return false
      }

      // prevent the foreground black flash on validation error
      this.$store.state.misc.loading = true

      // Champs non modifiable
      // if (!this.localIndependent.first_name || !this.localIndependent.last_name || !this.localIndependent.email) {
      //   return
      // }

      this.localIndependent.phone?.replaceAll('-', '')
      const payload = Object.assign({}, this.localIndependent)

      // preset to 2500€ when the prospect save his profil
      if ((!this.localIndependent.status || this.localIndependent.status === '') && this.settings.Entreprise.convention === this.constants.COLLECTIVE_CONVENTION.PORTAGE && !this.localIndependent?.profil?.workContract?.baseSalary) {
        this.localIndependent.profil.workContract.baseSalary = this.settings.RH.contratRH.baseSalary
      }

      // todo: only for admins ?
      if (this.positionCoefficient) {
        this.localIndependent.profil.workContract.positionCoefficient = this.positionCoefficient
      }

      if (this.localIndependent.profil.workContract.workTime.type === this.constants.WORK_TIME.FULL) {
        this.localIndependent.profil.workContract.workTime.value = this.settings.Paie.workTime.workingHoursOnFullTime
        this.localIndependent.profil.workContract.workTime.costValue = 0
      }

      // todo: hot fix, must specified in the back what props are updatable
      delete payload.last_document_contract
      delete payload.status
      delete payload.declarationFacturation
      delete payload.missions
      delete payload.pay
      delete payload.user
      delete payload.personalNumber
      delete payload.isEmployee
      delete payload.isRhValidated
      delete payload.disponible
      delete payload.previsionnel

      await this.$store.dispatch('independent/updateIndependent', {id: this.localIndependent.id, payload: payload})
      Alert.successMessage(this, 'saveProfil')
      this.$store.state.misc.loading = false
      return true
    },
    async searchPositionCoefficient(event) {
      if (!event.query.trim().length) {
        this.syntecPositionsCoefficients = [...this.settings.RH.syntecSalaryGrid];
      } else {
        this.syntecPositionsCoefficients = this.settings.RH.syntecSalaryGrid.filter((salaryLine) => {
          return salaryLine.position.toString().toLowerCase().includes(event.query.toString().toLowerCase()) ||
              salaryLine.coefficient.toString().toLowerCase().includes(event.query.toString().toLowerCase()) ||
              salaryLine.minimumBrutSalary.toString().toLowerCase().includes(event.query.toString().toLowerCase())
        });
      }
    },
    selectPositionCoefficient(payload) {
      this.positionCoefficient = payload
    },
    closeConfirmDialog() {
      this.confirmDialog = false
    },
    closeDialogCreditCardSupplierInvoice() {
      this.dialogCreditCardSupplierInvoiceIsActive = false
    },
    changeCreditCardOption() {
      if (this.me.role.name === this.constants.ROLES.ADMIN) {
        const payload = {
          id: this.localIndependent.id,
          payload: {
            id: this.localIndependent.id,
            credit_card: this.localIndependent.credit_card
          }
        }
        this.confirmDialogRefuse = () => {
          this.localIndependent.credit_card = !this.localIndependent.credit_card
          this.closeConfirmDialog()
        }
        if (this.localIndependent.credit_card) {
          // confirm to start the credit card service by showing up the price by year (thx to params) -- within validation create an NDF line with the negative price by year
          this.confirmDialogValidate = async () => {
            // disable credit card for this independent
            await this.$store.dispatch('independent/updateIndependent', payload)
            this.closeConfirmDialog()
            // open the dialog to create an furniture invoice
            this.dialogCreditCardSupplierInvoiceFee = this.settings.RH.carteBancaire.frais
            this.dialogCreditCardSupplierInvoiceIndependent = this.localIndependent
            this.dialogCreditCardSupplierInvoiceIsActive = true
          }
          // 'En validant, les portés séléctionnés ne pourront plus saisir un nombre de tickets restaurants lors de la déclaration de paie'
          this.confirmDialogTitle = `Activation du service de carte bancaire pour ${this.localIndependent.first_name} ${this.localIndependent.last_name}`
          this.confirmDialogInfo = `En validant, vous activerez le service de carte bancaire`
          this.confirmDialogQuestion = `Êtes-vous sûr de vouloir activer ce service pour ${this.localIndependent.first_name} ${this.localIndependent.last_name} ?`
          this.confirmDialog = true
        } else {
          this.confirmDialogValidate = async () => {
            // todo: stop renewing the service at the first of January - later on must had the field credit_card_renewal to false
            await this.$store.dispatch('independent/updateIndependent', payload)
            this.closeConfirmDialog()
          }
          // 'En validant, les portés séléctionnés ne pourront plus saisir un nombre de tickets restaurants lors de la déclaration de paie'
          this.confirmDialogTitle = `Désactivation du service de carte bancaire pour ${this.localIndependent.first_name} ${this.localIndependent.last_name}`
          this.confirmDialogInfo = `En validant, le renouvellement du service de carte bancaire tous les 1er Janvier ne sera pas effectué`
          this.confirmDialogQuestion = `Êtes-vous sûr de vouloir désactiver ce service pour ${this.localIndependent.first_name} ${this.localIndependent.last_name} ?`
          this.confirmDialog = true
        }
      }
    },
    changeTeleworking() {
      if (!this.localIndependent.profil.workContract.teleworkingDescription) {
        this.localIndependent.profil.workContract.teleworkingDescription = this.constants.TELEWORKING_BASE_DESCRIPTION
      }
    },
    updateOtherAppliedConditions(payload) {
      this.localIndependent.otherAppliedConditions = payload.applicableConditions
      this.changeProfileFields(payload.applicableConditions)
    },
    async sendRestaurantTicketsRenunciationLetter () {
      try {
        await this.$store.dispatch('rh/sendRenunciationLetter', { id: this.localIndependent.id })
        Alert.successMessage(this, 'sendRestaurantTicketsRenunciationLetter')
      } catch (e) {
        Alert.errorMessage(this, 'sendRestaurantTicketsRenunciationLetter')
      }
    },
    getChipMissionLabel (mission) {
      const currentDate = setDateWithoutHours(new Date())
      const missionLastManagementFeeActive = getLastValuePassed(mission.jsonCost.managementFeeValues,
          { monthOnly: true, currentDate: currentDate > mission.jsonMission.startingDate ? currentDate : mission.jsonMission.startingDate })
      //  - ${ missionLastManagementFeeActive?.cap } €
      return `${mission?.clientContract?.name ?? 'Indéfini'} - ${new Date(mission?.jsonMission?.startingDate).toLocaleDateString()} - ${ missionLastManagementFeeActive?.value ?? 'À définir' } ${missionLastManagementFeeActive?.value ? '%' : ''}`
    },
    openMissionManagementFees (mission) {
      this.missionSelected = JSON.parse(JSON.stringify(mission))
      process.nextTick(() => {
        this.openManagementFeeValues = true
      })
    },
    closeManagementFeeValuesDialog () {
      this.openManagementFeeValues = false
      this.missionSelected = null
    },
    // eslint-disable-next-line no-unused-vars
    changeProfileFields(event, fieldPath = null, option = {isAmendment: false}) {
      if (!this.enableChangeProfileFieldsNotify) return
      if (this.me.role.name !== this.constants.ROLES.ADMIN) return
      // if later on we need to prevent the user from getting notify by an automatic dialog that he don't saved his changes even if he return those fields to their original state
      // const isFieldDifferent = _.get(this.cloneMission, fieldPath, null) !== _.get(this.localMission, fieldPath, null)
      // , isDifferent: isFieldDifferent, fieldPath

      // const overloadOption = this.checkOptionOverload(option)
      // mission: this.localMission, fieldOption: overloadOption
      this.$emit('onProfileFieldChange', {})
    },
    // list in here all the rules on a changing field
    checkOptionOverload(optionFieldObject) {
      optionFieldObject.isAmendment = this.enableAmendment ? (optionFieldObject.isAmendment ?? false) : false
      return optionFieldObject
    },
  }
}
</script>
<style scoped>
@import '../../../../assets/css/stepsComponents.css';
.left-cards {
  width: 50%;
}

.right-cards {
  width: 50%;
}
</style>
<style>

.p-calendar .p-button {
  height: auto;
}
</style>
