<template>
  <DataTable :value="docs" responsiveLayout="scroll" class="p-datatable-sm">
    <Column field="document.title" header="Nom du fichier"></Column>
    <Column field="month" header="Mois"></Column>
    <Column field="year" header="Année"></Column>
  </DataTable>
</template>

<script>
export default {
  name: "TableauNDF",
  data () {
    return {
      docs: []
    }
  },
  async created () {
    this.docs.push({document: {title: '001'}, month: 'Janvier', year: '2021'})
    this.docs.push({document: {title: '002'}, month: 'Mai', year: '2021'})
    this.docs.push({document: {title: '003'}, month: 'Avril', year: '2021'})
  }
}
</script>

<style scoped>

</style>
