<template>
  <div v-if="simulation" class="flex-container non-scrollable">
    <div class="flex justify-content-end pr-2" style="gap: 1rem">
      <!--      <Button :label="$t('save')" class="p-button-rounded px-7 p-button-sm" @click="save" style="height: auto"/>-->
      <Button :label="$t('view', {target: $t('simulation').toLowerCase()})" class="p-button-rounded h-auto w-full px-3 xxl:w-auto" size="small" :disabled="!canGenerateSimulation" @click="generateSimulation" />
      <Button v-if="simulation.status === constants.SIMULATION_STATUS.DRAFT" :label="$t('save')" class="p-button-rounded h-auto w-full px-3 xxl:w-auto" size="small" :disabled="!canSaveSimulation" @click="save" />
    </div>
    <div class="scrollable">
      <fiche-simulation
          v-if="simulation"
          :view-r-h="true"
          ref="ficheSimu"
          @canGenerate="(value) => canGenerateSimulation = value"
          @canSave="(value) => canSaveSimulation = value"
      />
      <div class="spacer"></div>
    </div>
    <Dialog
        v-if="showSimulation"
        class="pdfIframe"
        content-class="flex p-0 flex-grow-1 justify-content-center"
        :breakpoints="{'1400px': '90vw'}"
        :style="{ width: '65vw', height: '75vh' }"
        :visible="showSimulation"
        :modal="true"
        :dismissable-mask="true"
        :draggable="false"
        @update:visible="showSimulation = false"
    >
      <div class="flex-container non-scrollable mx-auto flex flex-grow-1">
        <generated-simulations
            class="align-content-center"
            content-class="col-12"
            :simulation="simulation"
            :settings="settings"
            read-only
        />
      </div>
    </Dialog>
  </div>
</template>

<script>

import FicheSimulation from '@/components/FicheSimulation'
import { mapState } from 'vuex'
import Alert from '@/utils/Alert'
import {disablePComponentsOfCurrentPage, hasAccess, REGEX_VALIDATORS} from "@/utils/Misc";
import GeneratedSimulations from "@/components/GeneratedSimulations.vue";
export default {
  name: 'RHSimulation',
  components: {GeneratedSimulations, FicheSimulation },
  props: ['independent'],
  data () {
    return {
      active: 0,
      showSimulation: false,
      canGenerateSimulation: false,
      canSaveSimulation: false,
      pdf: '',
    }
  },
  watch: {
    independent () {
      this.updateSimulations()
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
    })
  },
  computed: {
    ...mapState({
      simulation: state => state.simulation.simulation,
      settings: state => state.misc.settings,
      constants: state => state.constants,
      me: state => state.auth.me,
    })
  },
  created () {
    this.updateSimulations()
  },
  methods: {
    updateSimulations () {
      if (this.independent) {
        this.$store.dispatch('simulation/getSimulationsIndependent', this.independent.id)
        this.$store.dispatch('simulation/getLastSimulation', this.independent.id)
      }
    },
    generateSimulation () {
      if (!this.canGenerateSimulation) return
      this.showSimulation = true
    },
    async save () {
      if (!this.simulation.commercial) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(1)'})
      } else {

        if (!(REGEX_VALIDATORS.EMAIL.test(this.simulation.independent.email))) {
          Alert.errorMessage(this, 'invalidEmail')
          return
        }

        await this.$store.dispatch('simulation/saveSimulation', this.simulation).then(() => {
          Alert.successMessage(this, 'saveSimulation')
          this.$refs.ficheSimu.cloneSimulation()
        })
      }
    },
  }
}
</script>

<style scoped>

</style>
