<template>
  <div class="flex px-1 py-1 md:px-1 lg:px-1 scrollable gap-4">
    <div class="flex flex-column cards" style="gap: 1rem; width: 40%">
      <Card>
        <template #title>
          <label>Sortie</label>
        </template>
        <template #content>
          <div class="field mb-3 col-12 md:col-12">
            <label for="outReason">Motif de sortie</label>
            <div>
              <Dropdown input-id="outReason" v-model="localIndependent.sortie.type" class="w-6" :options="motifsSortie"/>
            </div>
          </div>
          <div class="field mb-3 col-12 md:col-12">
            <label for="independent-entry">Date d'entrée</label>
            <div>
              <Calendar input-id="independent-entry" class="w-6"
                        :model-value="new Date(localIndependent.missions?.sort((a, b) => { return Date.parse(a.jsonMission.startingDate) > Date.parse(b.jsonMission.startingDate) })[0].jsonMission.startingDate)" date-format="dd/mm/yy"/>
            </div>
          </div>
          <div class="field mb-3 col-12 md:col-12">
            <label for="outDate">Date de sortie</label>
            <div>
              <Calendar input-id="outDate" v-model="localIndependent.sortie.date" class="w-6"
                        date-format="dd/mm/yy" show-icon/>
            </div>
          </div>
          <div class="field mb-3 col-12 md:col-12">
            <label for="monthSeniority">Mois d'ancienneté</label>
            <div>
              <InputNumber input-id="monthSeniority" :model-value="ancienneteComputed" class="w-6" highlightOnFocus disabled/>
            </div>
          </div>
          <div class="field mb-3 col-12 md:col-12">
            <label>Convention</label>
            <div>
              <InputText :model-value="convention" type="text" disabled class="w-6"/>
            </div>
          </div>
        </template>
      </Card>
      <Card v-if="localIndependent.sortie.type === 'Fin de période d\'essai'">
        <template #title>
          <div class="flex align-items-center" style="gap: 1rem">
            <label>Fin de période d'essai</label>
          </div>
        </template>
        <template #content>
          <div class="flex align-items-center justify-content-between">
            <span>Courrier</span>
            <Button :icon="independentDocuments.find(d => d.type === constants.DOCUMENT_TYPE.RESIGNATION_LETTER) ? 'pi pi-eye' : 'pi pi-upload'"
                    @click="openPreviewDocument(constants.DOCUMENT_TYPE.RESIGNATION_LETTER)"/>
          </div>
        </template>
      </Card>
      <Card v-if="localIndependent.sortie.type === 'Démission'">
        <template #title>
          <div class="flex align-items-center" style="gap: 1rem">
            <label>Démission</label>
          </div>
        </template>
        <template #content>
          <div class="flex align-items-center justify-content-between">
            <span>Courrier</span>
            <Button :icon="independentDocuments.find(d => d.type === constants.DOCUMENT_TYPE.RESIGNATION_LETTER) ? 'pi pi-eye' : 'pi pi-upload'"
                    @click="openPreviewDocument(constants.DOCUMENT_TYPE.RESIGNATION_LETTER)"/>
          </div>
        </template>
      </Card>
      <Card v-if="localIndependent.sortie.type === 'Rupture conventionnelle'">
        <template #title>
          <div class="flex align-items-center" style="gap: 1rem">
            <label>Rupture conventionnelle</label>
          </div>
        </template>
        <template #content>
          <div class="flex flex-column" style="gap: 1rem">
            <div class="flex align-items-center justify-content-between">
              <span>Formulaire de DREETS</span>
              <Button :icon="independentDocuments.find(d => d.type === constants.DOCUMENT_TYPE.DREETS_FORM) ? 'pi pi-eye' : 'pi pi-upload'"
                      @click="openPreviewDocument(constants.DOCUMENT_TYPE.DREETS_FORM)"/>
            </div>
            <div class="flex align-items-center justify-content-between">
              <span>Document d'accord</span>
              <Button :icon="independentDocuments.find(d => d.type === constants.DOCUMENT_TYPE.AGREEMENT_DOCUMENT) ? 'pi pi-eye' : 'pi pi-upload'"
                      @click="openPreviewDocument(constants.DOCUMENT_TYPE.AGREEMENT_DOCUMENT)"/>
            </div>
            <div class="flex align-items-center justify-content-between">
              <span>Document d'entretien</span>
              <Button :icon="independentDocuments.find(d => d.type === constants.DOCUMENT_TYPE.INTERVIEW_DOCUMENT) ? 'pi pi-eye' : 'pi pi-upload'"
                      @click="openPreviewDocument(constants.DOCUMENT_TYPE.INTERVIEW_DOCUMENT)"/>
            </div>
            <div class="flex align-items-center justify-content-between">
              <span>Attestation d'homologation</span>
              <Button :icon="independentDocuments.find(d => d.type === constants.DOCUMENT_TYPE.APPROVAL_CERTIFICATE) ? 'pi pi-eye' : 'pi pi-upload'"
                      @click="openPreviewDocument(constants.DOCUMENT_TYPE.APPROVAL_CERTIFICATE)"/>
            </div>
          </div>
        </template>
      </Card>
      <Card v-if="localIndependent.sortie.type">
        <template #title>
          <label>Préparation de sortie</label>
        </template>
        <template #content>
          <div class="field" style="gap: 0.5rem;">
            <label for="courrierSortie">Courrier de sortie</label>
            <Checkbox :binary="true" id="courrierSortie" type="text" v-model="localIndependent.sortie.preparation.courrier" />
          </div>
          <div class="field" style="gap: 0.5rem;">
            <label for="optimisation">Optimisation</label>
            <Checkbox :binary="true" id="optimisation" type="text" v-model="localIndependent.sortie.preparation.optimisation" />
          </div>
          <div class="field" style="gap: 0.5rem;">
            <label for="fraisSortie">Frais de sortie</label>
            <Checkbox :binary="true" id="fraisSortie" type="text" v-model="localIndependent.sortie.preparation.frais" />
          </div>
          <div class="field" style="gap: 0.5rem;">
            <label for="ndf" :style="{color: ndfsOk ? 'black' : 'red', fontWeight: ndfsOk ? '' : 'bold'}">Notes de frais validées</label>
            <Checkbox :binary="true" id="ndf" type="text" v-model="localIndependent.sortie.preparation.ndf" />
          </div>
          <div class="field" style="gap: 0.5rem;">
            <label for="facturesfournisseurs">Factures fournisseurs (CB, LOA, ...)</label>
            <Checkbox :binary="true" id="facturesfournisseurs" type="text" v-model="localIndependent.sortie.preparation.factures_fournisseurs" />
          </div>
          <div class="field" style="gap: 0.5rem;">
            <label for="facturation">Facturation</label>
            <Checkbox :binary="true" id="facturation" type="text" v-model="localIndependent.sortie.preparation.facturation" />
          </div>
          <div class="field" style="gap: 0.5rem;">
            <label for="regulIKM">Régularisation IKM</label>
            <Checkbox :binary="true" id="regulIKM" type="text" v-model="localIndependent.sortie.preparation.regulIKM" />
          </div>
          <div class="field" style="gap: 0.5rem;">
            <label for="blockCB">Bloquer la CB niveau bancaire</label>
            <Checkbox :binary="true" id="blockCB" type="text" v-model="localIndependent.sortie.preparation.blockCB" />
          </div>
        </template>
      </Card>
    </div>
    <div class="flex flex-column cards" style="gap: 1rem; width: 40%">
      <Card v-if="localIndependent.sortie.type === 'Rupture conventionnelle'">
        <template #title>
          <label>Frais :</label>
        </template>
        <template #content>
          <div class="field">
            <label>Frais de sortie</label>
            <InputText type="text" v-model="localIndependent.sortie.frais.sortie" />
          </div>
          <div class="field">
            <label>PEE</label>
            <InputText type="text" v-model="localIndependent.sortie.frais.pee" />
          </div>
          <div class="field">
            <label>PERCO</label>
            <InputText type="text" v-model="localIndependent.sortie.frais.perco" />
          </div>
          <div class="field">
            <label>Provision de rupture conventionnelle</label>
            <InputText type="text" v-model="localIndependent.sortie.frais.provisionRupture" />
          </div>
          <div class="field">
            <label>Provision d'indemnité de RC</label>
            <InputText type="text" v-model="localIndependent.sortie.frais.provisionIndemnite" />
          </div>
          <div class="field">
            <label>Reste à verser</label>
            <InputText type="text" disabled model-value="56" />
          </div>
        </template>
      </Card>
      <Card v-if="localIndependent.sortie.type === 'Rupture conventionnelle'">
        <template #title>
          <div class="flex justify-content-between">
            <label>Salaires :</label>
            <p class="m-0 mb-2 p-0 text-sm font-bold line-height-3 mr-3 underline cursor-pointer" @click="showSalaires">Déclarer les 12 derniers mois de salaire</p>
          </div>
        </template>
        <template #content>
          <div class="field">
            <label>Salaire moyen 3 mois</label>
            <InputNumber :model-value="avgSalaries3" disabled locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
          </div>
          <div class="field">
            <label>Salaire moyen 12 mois</label>
            <InputNumber :model-value="avgSalaries12" disabled locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
          </div>
          <div class="field">
            <label>Moyenne retenue</label>
            <InputNumber disabled :model-value="moyenneRetenueComputed" locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
          </div>
          <div class="field">
            <label>Indemnité estimée</label>
            <InputNumber disabled :model-value="indemniteRCComputed" locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
          </div>
        </template>
      </Card>
      <tableau-documents :nsecu="localIndependent.socialSecurityCode"></tableau-documents>
      <Card>
        <template #title>
          <label>Documents de sortie :</label>
        </template>
        <template #content>
          <div class="flex flex-column" style="gap: 1rem">
            <div class="flex align-items-center justify-content-between">
              <span>Certificat de travail</span>
              <Button :icon="independentDocuments.find(d => d.type === constants.DOCUMENT_TYPE.EMPLOYMENT_CERTIFICATE) ? 'pi pi-eye' : 'pi pi-upload'"
                      @click="openPreviewDocument(constants.DOCUMENT_TYPE.EMPLOYMENT_CERTIFICATE)"/>
            </div>
            <div class="flex align-items-center justify-content-between">
              <span>Solde de tout compte</span>
              <Button :icon="independentDocuments.find(d => d.type === constants.DOCUMENT_TYPE.FINAL_BALANCE) ? 'pi pi-eye' : 'pi pi-upload'"
                      @click="openPreviewDocument(constants.DOCUMENT_TYPE.FINAL_BALANCE)"/>
            </div>
            <div class="flex align-items-center justify-content-between">
              <span>Attestation Pôle Emploi</span>
              <Button :icon="independentDocuments.find(d => d.type === constants.DOCUMENT_TYPE.POLE_EMPLOI_CERTIFICATE) ? 'pi pi-eye' : 'pi pi-upload'"
                      @click="openPreviewDocument(constants.DOCUMENT_TYPE.POLE_EMPLOI_CERTIFICATE)"/>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <Dialog :draggable="false" v-model:visible="displayProgress" :style="{width: '500px'}" :modal="true" class="sortie-dialog" content-class="dialog-content" :show-header="false">
      <ProgressBar :value="percent"></ProgressBar>
    </Dialog>
    <Dialog :draggable="false" dismissable-mask v-model:visible="salairesModal" :modal="true" :show-header="false">
      <div class="flex justify-content-between p-4" style="gap: 5rem">
        <Card style="padding: 3rem;">
          <template #title>
            <label>Salaires :</label>
          </template>
          <template #content>
            <div class="field" v-for="(date, index) in months" :key="date">
              <label>{{ date }}</label>
              <InputNumber v-model="salaries[index]" locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
            </div>
          </template>
        </Card>
        <div>
          <tableau-documents class="mb-4" :nsecu="localIndependent.socialSecurityCode"></tableau-documents>
          <span class="underline cursor-pointer" @click="confirmSalaries">Valider</span>
        </div>
      </div>
    </Dialog>
    <div class="float-button">
      <Button label="Sauvegarder" class="w-full p-3" @click="save"/>
    </div>
  </div>
  <dialog-file-preview :is-active="filePreviewIsActive" :firstUploadButtonLabel="'Téléverser un document'"
                       :file-id="fileId" :document-id="documentId" :document-type="documentType"
                       :independent-id="independent.id"
                       replaceFileButton delete-button validationDialog closeButton modal
                       dialog-title="Prévisualisation" file-mime-type="application/pdf"
                       @deleteFile="onFileEndAction" @replaceFile="onFileEndAction" @closeDialog="closeFilePreviewDialog"
                       @firstFileUpload="onFileEndAction" @closePreview="closeFilePreviewDialog"
  />
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {capitalizeWord, disablePComponentsOfCurrentPage, hasAccess} from "@/utils/Misc";
import TableauDocuments from "@/components/RH/TableauDocuments";
import DialogFilePreview from "@/components/DialogFilePreview";

export default {
  name: "Sortie",
  components: {TableauDocuments, DialogFilePreview},
  data () {
    return {
      filePreviewIsActive: false,
      fileId: null,
      documentId: null,
      documentType: null,

      independentDocuments: [],
      convention: '',
      salairesModal: false,
      localIndependent: null,
      localFinEssai: false,
      localDemission: false,
      localRupture: false,
      localFinCdd: false,
      displayProgress: false,
      percent: 0,
      salaries: {},
      avgSalaries12: null,
      avgSalaries3: null,
      months: [],
      motifsSortie: ['Fin de période d\'essai', 'Démission', 'Rupture conventionnelle', 'Fin de CDD'],
      ndfsOk: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
    })
  },
  computed: {
    ...mapGetters({
      independent: 'independent/getIndependent',
    }),
    ...mapState({
      settings: state => state.misc.settings,
      constants: state => state.constants
    }),
    ancienneteComputed () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const start = new Date(this.localIndependent.missions.sort((a, b) => { return Date.parse(a.jsonMission.startingDate) > Date.parse(b.jsonMission.startingDate) })[0].jsonMission.startingDate)
      const end = this.localIndependent.sortie.date && this.localIndependent.sortie.date !== '' ? new Date(this.localIndependent.sortie.date) : new Date()
      if (end && start) {
        return this.getMonthDifference(start, end)
      } else {
        return 0
      }
    },
    moyenneRetenueComputed () {
      return Math.max(this.avgSalaries3, this.avgSalaries12)
    },
    indemniteRCComputed () {
      if (!this.convention || !this.moyenneRetenueComputed) return 0

      let coef
      // todo: Nothing to do in Portage convention ?
      switch(this.convention) {
        case this.constants.COLLECTIVE_CONVENTION.SYNTEC:
          coef = this.ancienneteComputed >= 24 ? 3 : 4;
          break;
        default:
          coef = 4;
      }

      return (this.moyenneRetenueComputed / coef) * this.ancienneteComputed / 12
    }
  },
  watch: {
    independent() {
      process.nextTick(async () => {
        await this.init()
      })
    }
  },
  async created () {
    await this.init()
  },
  methods: {
    async init () {
      this.localIndependent = JSON.parse(JSON.stringify(this.independent))
      this.localIndependent.sortie.date = this.independent?.sortie?.date ? new Date(this.independent.sortie.date) : ''
      this.months = this.getDates()
      this.convention = this.settings.Entreprise.convention
      await this.initIndependentDocument()
      const ndfs = await this.$store.dispatch('ndf/getAllNDFSByIndependent', this.localIndependent.id)
      if (ndfs.find(n => n.adminStatus === 'validate')) this.ndfsOk = false
      await this.$store.dispatch('invoice/getInvoicesByIndependent', this.localIndependent.id)
    },
    async openPreviewDocument (documentType) {
      const file = this.independentDocuments.find(d => d.type === documentType)
      if (file) {
        this.fileId = file.document.id
        this.documentId = file.id
      } else {
        this.fileId = null
        this.documentId = null
      }
      this.documentType = documentType
      this.filePreviewIsActive = true
    },
    closeFilePreviewDialog () {
      this.filePreviewIsActive = false
    },
    async initIndependentDocument () {
      this.independentDocuments = await this.$store.dispatch('documents/getAllIndependentDocument', { id: this.localIndependent.id })
    },
    async saveIndependent () {
      await this.$store.dispatch('independent/updateIndependent', { id: this.localIndependent.id, payload: this.localIndependent })
    },
    async onFileEndAction ()  {
      await this.saveIndependent()
      await this.initIndependentDocument()
    },
    async save () {
      this.$store.state.misc.loading = true
      if (this.independent.status !== null && this.independent.status !== this.constants.INDEPENDENT_STATUS.DRAFT &&
          this.independent.status !== this.constants.INDEPENDENT_STATUS.OUT) {
        this.localIndependent.status = this.$store.state.constants.INDEPENDENT_STATUS.OUT_GOING
      }
      await this.saveIndependent()
      this.$store.state.misc.loading = false
    },
    getMonthDifference (start, end) {
      let diffMonths = end.getMonth() - start.getMonth() + 12 * (end.getFullYear() - start.getFullYear())
      if (start.getDate() - end.getDate() > 0) diffMonths--
      return diffMonths
    },
    confirmSalaries () {
      let sum = 0
      for (let i = 0; i < 12; i++) sum += this.salaries[i]?? 0
      this.avgSalaries12 = sum / 12

      let sum2 = 0
      for (let i = 0; i < 3; i++) sum2 += this.salaries[i]?? 0
      this.avgSalaries3 = sum2 / 3

      this.salairesModal = false
    },
    getDates () {
      const dates = []
      const date = new Date()
      date.setDate(1)
      for (let i = 0; i < 12; i++) {
        date.setMonth(date.getMonth() - 1)
        dates.push(capitalizeWord(date.toLocaleString('default', { month: 'long', year: 'numeric' })))
      }

      return dates
    },
    showSalaires () {
      this.salairesModal = true
    }
  }
}
</script>

<style>
.sortie-dialog .dialog-content {
  background-color: transparent !important;
  padding: 0 !important;
}
</style>
