<template>
  <div class="flex flex-column p-5" style="gap: 2rem">
    <tableau-document-sortie-independent :independent-id="independent.id" />
    <tableau-document-sortie-independent :independent-id="independent.id" document-signed class="mt-4"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableauDocumentSortieIndependent from "@/components/RH/TableauDocumentSortieIndep";
export default {
  name: "SortieIndependent",
  components: {TableauDocumentSortieIndependent},
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      independent: 'independent/getIndependent',
    })
  }
}
</script>

<style scoped>

</style>
