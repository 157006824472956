<template>
  <div>
    <div v-if="loaded && documents.length > 0">
      <div
          v-for="(document, index) of documents"
          :key="index"
          class="p-3 border-round shadow-2 surface-card mb-2"
          :class="document.multiple ? 'hidden-box' : 'cursor-pointer hover:bg-primary hover:text-white transition-duration-100'"
          @click="openDocumentPreview(document)"
      >
        <div class="flex align-items-center justify-content-between">
          <div
              style="width:48px;height:48px;border-radius:10px"
              class="bg-teal-100 inline-flex align-items-center justify-content-center mr-3"
          >
            <img :src="require(`../../assets/images/icon/${document.icon}`)" width="36" height="36"/>
          </div>
          <div>
            <div class="text-xl font-medium mb-2">{{ $t(document.labelKey) }}</div>
          </div>
          <div>
            <!--            todo: in case of mandatory document && nonActive -> pi-exclamation-triangle-->
            <!--            todo: else && nonActive pi-minus text-grey-->
            <!--            assume that every document is mandatory-->
            <i
                class="pi"
                :class="isDocumentExist(document) ? 'pi-download text-white' : document.mandatory ? 'pi-exclamation-triangle text-red-500' : ''"
                :style="document.multiple && isDocumentExist(document) ? 'visibility: hidden' : ''"
            />
          </div>
        </div>
        <IndependentDocumentSubDocument v-if="document.multiple && document.type !== constants.DOCUMENT_TYPE.OTHER"
                                        :documents="allIndependentDocumentsByType[document.type]" class="mt-3"
                                        @openDocument="(payload) => openDocumentPreview(document, payload.independentDocument, payload.forceCreation)"
                                        @addDocument="(payload) => openDocumentPreview(document, payload.independentDocument, payload.forceCreation)"/>
        <IndependentDocumentSubDocument v-if="document.multiple && document.type === constants.DOCUMENT_TYPE.OTHER"
                                        :documents="independentOtherDocuments" class="mt-3" custom-type
                                        @openDocument="(payload) => openDocumentPreview(document, payload.independentDocument, payload.forceCreation)"
                                        @addDocument="(payload) => openDocumentPreview(document, payload.independentDocument, payload.forceCreation)"/>
      </div>
    </div>
    <div v-else-if="loaded && documents.length === 0">
      Il n'existe aucun document pour ce porté
    </div>
    <div v-else>
      <ProgressSpinner/>
    </div>
  </div>
  <dialog-file-preview
      :is-active="filePreviewIsActive" :dialog-title="filePreviewTitle"
      :replaceFileButton="filePreviewReplaceButton" :firstUploadButtonLabel="'Téléverser un document'"
      :delete-button="fileCanBeDeleted" :define-file-type="fileTypeCustom" :independent-id="independentId"
      :file-type-options="fileTypeOptions" :file-id="fileId" :document-id="documentId" :document-type="documentType"
      closeButton modal auto-upload autoLoadFile is-document
      @closePreview="resetDefaultFilePreview"
      @closeDialog="resetDefaultFilePreview"
      @replaceFile="updateDocument"
      @firstFileUpload="createDocument"
      @deleteFile="deleteDocument"
  />
</template>

<script>
import {mapState} from "vuex";
import DialogFilePreview from "@/components/DialogFilePreview.vue";
import IndependentDocumentSubDocument from "@/components/RH/IndependentDocumentSubDocument.vue";

export default {
  name: "IndependentDocuments",
  components: {IndependentDocumentSubDocument, DialogFilePreview},
  emits: ['allDocumentTypeValidated'],
  props: {
    independentId: {
      type: String,
      required: true
    },
    documentsTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      documents: [],
      independentDocuments: [],
      independentOtherDocuments: [],
      loaded: false,

      filePreviewIsActive: false,
      filePreviewTitle: '',
      filePreviewReplaceButton: false,
      fileCanBeDeleted: false,
      fileTypeCustom: false,
      fileTypeOptions: [],
      fileId: null,
      documentId: null,
      documentType: null,

      currentDocument: null,
      currentIndependentDocument: null,
      allIndependentDocumentsByType: {}
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
      settings: state => state.misc.settings,
    })
  },
  async created() {
    await this.initDocuments()
  },
  methods: {
    async initDocuments() {
      this.loaded = false
      this.independentOtherDocuments = await this.$store.dispatch('documents/getAllIndependentOtherDocument', {id: this.independentId})
      this.independentDocuments = await this.$store.dispatch('documents/getAllIndependentDocument', {id: this.independentId})
      this.documents = this.constants.data.allDocuments.filter((document) =>
          this.documentsTypes.findIndex(documentType => document?.type === documentType) !== -1
      )
      this.constants.data.allDocuments.forEach((document) => {
        this.allIndependentDocumentsByType[document.type] = this.independentDocuments.filter((independentDocument) => independentDocument.type === document.type)
      })
      this.isAllDocumentTypeValidated()
      this.fileTypeOptions = this.settings.Entreprise.documentTypes
      this.loaded = true
    },
    isDocumentExist(document) {
      return this.independentDocuments.findIndex((independentDocument) => document?.type === independentDocument?.type) !== -1
    },
    async openDocumentPreview(document, independentDocument = null, forceCreation = false) {
      if (document.multiple && independentDocument === null && !forceCreation) return

      const foundIndependentDocument = independentDocument ?? this.independentDocuments.find((independentDocument) =>
          independentDocument.type === document.type
      )

      this.documentType = document.type
      this.fileTypeCustom = document.customType
      this.currentDocument = document
      if ((foundIndependentDocument === undefined && independentDocument === null) || forceCreation) {
        this.filePreviewTitle = this.$t(document.labelKey)
        this.filePreviewReplaceButton = false
        this.filePreviewIsActive = true
        return
      }

      if (foundIndependentDocument?.document?.id || independentDocument !== null) {
        this.currentIndependentDocument = foundIndependentDocument
        this.fileId = this.currentIndependentDocument.document.id
        this.documentId = this.currentIndependentDocument.id
        this.fileCanBeDeleted = document.multiple
        this.filePreviewReplaceButton = true
        this.filePreviewIsActive = true
      }
    },
    resetDefaultFilePreview() {
      this.filePreviewIsActive = false
      this.currentDocument = null
      this.currentIndependentDocument = null
      this.filePreviewSrc = null
      this.filePreviewTitle = 'Document'
      this.filePreviewReplaceButton = false
      this.fileTypeCustom = false
      this.fileId = null
      this.documentId = null
      this.documentType = null
      // if needed to have custom types for different document
      // this.fileTypeOptions = []
    },
    isAllDocumentTypeValidated() {
      const validatedDocumentType = {}
      // not managed multiple document
      this.documents.forEach((document) => {
        validatedDocumentType[document.type] = (this.independentDocuments.findIndex((independentDocument) => independentDocument.type === document.type) !== -1) || !document.mandatory
      })
      this.$emit('allDocumentTypeValidated', (Object.getOwnPropertyNames(validatedDocumentType)).every(propertyName => validatedDocumentType[propertyName]))
    },
    async createDocument() {
      this.resetDefaultFilePreview()
      // todo: should update via the store return response
      await this.initDocuments()
    },
    async updateDocument() {
      this.resetDefaultFilePreview()
      // todo: should update via the store return response
      await this.initDocuments()
    },
    async deleteDocument() {
      this.resetDefaultFilePreview()
      // todo: should update via the store return response
      await this.initDocuments()
    }
  }
}
</script>

<style scoped>
</style>
