<template>
  <Dialog
      v-model:visible="dialogActive"
      :draggable="false"
      :style="{width: '1000px'}"
      :modal="true"
      :show-header="false"
      content-class="dialog-content"
  >
    <h3 class="mb-0">Création d'une facture fournisseur pour le service de carte bancaire</h3>
    <p class="mt-0 mb-0">
      Souhaitez-vous créer une facture fournisseur pour le service de carte bancaire ?
      <i class="ml-1 pi pi-info-circle text-primary cursor-pointer" @click="this.showInfo = !this.showInfo" />
    </p>
    <p v-if="showInfo" class="pay-info mt-0 text-sm">
      La facture fournisseur créée sera directement valider et rembourser
    </p>
    <div class="flex flex-row align-items-center mb-3">
      <label for="creditCardFee" class="w-2 block text-900 font-medium">Frais de carte bancaire</label>
      <InputNumber id="creditCardFee" v-model="creditCardFee" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" suffix=" € / An"/>
    </div>
    <div class="flex flex-row-reverse">
      <Button label="Valider la facture fournisseur" :disabled="disabledConfirm" @click="confirmSupplierInvoice"/>
      <Button class="p-button-outlined mr-2" label="Ne pas créer de facture fournisseur" @click="closeDialog"/>
    </div>
  </Dialog>
</template>

<script>
export default {
  name: "DialogCreditCardSupplierInvoice",
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    defaultCreditCardFee: {
      type: Number,
      default: 0,
      required: true
    },
    independent: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  watch: {
    isActive (value) {
      if (value) {
        this.initFields()
      }
      this.dialogActive = value
    }
  },
  data () {
    return {
      dialogActive: false,
      showInfo: false,

      creditCardFee: 0
    }
  },
  computed: {
    disabledConfirm () {
      return this.creditCardFee ? !(this.creditCardFee >= 0) : true
    }
  },
  created() {
    this.dialogActive = this.isActive
  },
  methods: {
    initFields () {
      this.creditCardFee = this.defaultCreditCardFee
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    async confirmSupplierInvoice () {
      // create the supplier invoice
      await this.$store.dispatch('rh/updateIndependentCreditCard', { independentId: this.independent.id, creditCardFee: this.creditCardFee })
      this.closeDialog()
    }
  }
}
</script>

<style>
.dialog-content {
  padding: 2rem !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 1rem !important;
  background-color: white;
}
</style>
