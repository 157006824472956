<template>
  <Card class="shadow-2">
    <template #title>
      <div class="flex justify-content-center align-items-center">
        <i class="fa fa-file-signature mr-2"/>
        <div class="text-900 text-xl font-medium mr-4 ml-4 text-center">Documents annexes</div>
      </div>
    </template>
    <template #content>
      <div class="col-12">
        <!--  todo: document imported by the RH are directly at the validated status ?  -->
        <!--  v-if="me.role.name === constants.ROLES.ADMIN"  -->
        <Toolbar class="mb-2 p-2">
          <template #end>
            <Button icon="pi pi-plus" label="Ajouter un document" @click="openDocumentCreation"/>
          </template>
        </Toolbar>
        <DataTable :value="independentOtherDocuments" responsiveLayout="scroll" class="p-datatable-sm"
                   :loading="loading" selectionMode="single" scrollable scroll-height="300px"
                   @row-click="openContractPreview">
          <Column field="document.title" header="Nom du fichier" sortable sortField="document.title">
            <template #body="{data}">
              <span class="text-blue-500">
                {{ data?.document?.title }}
              </span>
            </template>
          </Column>
          <Column field="type" header="Type" sortable sortField="type">
            <template #body="{data}">
              <span>
                {{ data.type }}
              </span>
            </template>
          </Column>
          <Column field="document.uploaded_on" header="Date d'import" sortable sortField="document.uploaded_on">
            <template #body="{data}">
              <span class="uppercase">
                {{
                  new Date(data?.document?.uploaded_on).toLocaleDateString('fr-FR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  })
                }}
              </span>
            </template>
          </Column>
          <Column field="document.uploaded_by" header="Origine" sortable sortField="document.uploaded_by.id">
            <template #body="{data}">
              <span>
                {{ getOrigin(data) }}
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </template>
  </Card>
  <dialog-file-preview :is-active="filePreviewIsActive" :file-type-options="fileTypeOptions" :define-file-type="fileTypeCustom"
                       :independent-id="independentId" :file-id="fileId" :document-id="documentId"
                       :firstUploadButtonLabel="'Téléverser un document'"
                       validationDialog closeButton modal replaceFileButton delete-button
                       dialog-title="Prévisualisation" file-mime-type="application/pdf"
                       @closePreview="resetDefaultFilePreview" @closeDialog="resetDefaultFilePreview"
                       @replaceFile="refresh" @firstFileUpload="refresh" @deleteFile="refresh"
  />
</template>

<script>
import DialogFilePreview from "@/components/DialogFilePreview.vue";
import {mapState} from "vuex";

export default {
  name: "IndependentOtherDocuments",
  emits: ['refreshContracts'],
  components: {DialogFilePreview},
  props: {
    independentId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,

      previewContractDialog: false,
      previewContractSrc: null,

      independentOtherDocuments: [],

      filePreviewIsActive: false,
      fileTypeOptions: [],
      fileId: null,
      documentId: null,
      // must be true in all cases
      fileTypeCustom: true,
      currentSelectedDocument: null
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      constants: state => state.constants,
      settings: state => state.misc.settings
    })
  },
  async created() {
    await this.initDocuments()
  },
  methods: {
    async initDocuments() {
      this.loading = true
      this.independentOtherDocuments = await this.$store.dispatch('documents/getAllIndependentOtherDocument', {id: this.independentId})
      this.fileTypeOptions = this.settings.Entreprise.documentTypes
      this.loading = false
    },
    async openDocumentCreation() {
      this.fileId = null
      this.documentId = null
      this.filePreviewIsActive = true
    },
    async openContractPreview(document) {
      this.currentSelectedDocument = document.data
      this.fileId = document.data.document.id
      this.documentId = document.data.id
      this.filePreviewIsActive = true
    },
    resetDefaultFilePreview() {
      this.filePreviewIsActive = false
      this.currentSelectedDocument = null
    },
    async refresh () {
      this.resetDefaultFilePreview()
      await this.initDocuments()
    },
    getOrigin (data) {
      if (data.document.uploaded_by.id === this.me.id) return 'Moi'
      return this.settings.Entreprise.raisonSociale
    }
  }
}
</script>

<style scoped>
.link-hover :hover {
  cursor: pointer;
}
</style>
