<template>
  <div>
    <div
        v-for="(independentDocument, independentDocumentIndex) of documents"
        :key="independentDocumentIndex"
        class="cursor-pointer p-3 border-round shadow-2 surface-card mb-1 hover:bg-primary transition-duration-100"
        @click.stop="openDocumentPreview(independentDocument)"
    >
      <div class="flex align-items-center justify-content-between">
        <div>
          <div class="text-900 text-xl font-medium">{{ `${customType ? `${independentDocument.type} - ` : ''}${independentDocument.document.title}` }}</div>
        </div>
        <div>
          <i
              class="pi pi-download text-white"
          />
        </div>
      </div>
    </div>
    <Button label="Ajouter un document" class="p-button-outlined w-full mt-2" style="height: auto;" @click="addDocumentPreview(null, true)"/>
  </div>
</template>

<script>
export default {
  name: "IndependentDocumentSubDocument",
  emits: ['addDocument', 'openDocument'],
  props: {
    documents: {
      type: Array,
      required: true
    },
    customType: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    addDocumentPreview (independentDocument = null, forceCreation = false) {
      this.$emit('addDocument', { independentDocument, forceCreation })
    },
    openDocumentPreview (independentDocument = null, forceCreation = false) {
      this.$emit('openDocument', { independentDocument, forceCreation })
    }
  }
}
</script>

<style scoped>

</style>
