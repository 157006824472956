<!-- todo: THERE CERTAIN THINGS TO ADD/CHANGE HERE -->
<template>
  <div>
    <div class="text-900 text-xl font-medium mr-4 ml-4 text-center">{{ documentSigned ? 'Document signés' : 'Documents à signer' }}</div>
    <DataTable :value="docs" responsiveLayout="scroll" class="p-datatable-sm" :loading="loading">
      <Column field="title" header="Nom du fichier"></Column>
      <Column field="uploaded_on" header="Mois">
        <template #body="{data}">
        <span>
          {{ new Date(data.uploaded_on).toLocaleDateString('fr-FR', { month: 'long' }) }}
        </span>
        </template>
      </Column>
      <Column field="uploaded_on" header="Année">
        <template #body="{data}">
        <span>
          {{ new Date(data.uploaded_on).toLocaleDateString('fr-FR', { year: 'numeric'}) }}
        </span>
        </template>
      </Column>
      <Column field="uploaded_on" header="Date de téléchargement"></Column>
    </DataTable>
  </div>
<!-- todo: might should add some of the action buttons -->
  <dialog-file-preview dialog-title="Document signé" :is-active="previewDocumentDialog" :file-id="fileId"/>
</template>

<script>
import DialogFilePreview from "@/components/DialogFilePreview";

export default {
  name: "TableauDocumentSortieIndependent",
  components: {DialogFilePreview},
  props: {
    independentId: {
      type: String,
      required: true
    },
    documentSigned: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      loading: true,
      independentDocument: null,
      docs: [],

      previewDocumentDialog: false,
      fileId: null
    }
  },
  async created () {
    // todo: need to get the document type according, see how goes the rest
    this.loading = true
    this.independentDocument = await this.$store.dispatch('independent/getIndependentExitDocuments', { id: this.independentId })
    if (!this.independentDocument.sortie.raisonSortie?.files?.dreets) {
      this.docs = []
    } else {
      this.docs = [this.independentDocument.sortie.raisonSortie?.files?.dreets]
    }
    this.loading = false
  },
  methods: {
    async openContractPreview (file) {
      this.fileId = file
      this.previewDocumentDialog= true
    }
  }
}
</script>

<style scoped>

</style>
