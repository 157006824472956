<template>
  <div class="flex-container non-scrollable">
    <div v-if="independent" class="flex flex-column pb-3">
      <div class="flex flex-row align-items-center">
        <NameHeader :independent="independent" :backward-button="me?.role.name === constants.ROLES.ADMIN" />
      </div>
      <div class="mt-4 surface-ground shadow-2">
        <div class="">
          <ul class="p-0 m-0 list-none flex overflow-x-auto select-none">
            <li>
              <a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 p-ripple"
                 :class="{'border-primary-500 text-primary-500 hover:border-primary-500': tab === 0, 'text-700 border-transparent': tab !== 0}" @click="changeTab(0)">
                <i class="fa fa-rocket mr-2"></i>
                <span class="font-bold">Informations</span>
              </a>
            </li>
            <li>
              <a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 p-ripple"
                 :class="{'border-primary-500 text-primary-500 hover:border-primary-500': tab === 1, 'text-700 border-transparent': tab !== 1}" @click="changeTab(1)">
                <i class="fa-solid fa-file-contract mr-2"></i>
                <span class="font-medium">Simulation</span>
              </a>
            </li>
            <li>
              <a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 p-ripple"
                 :class="{'border-primary-500 text-primary-500 hover:primary-blue-500': tab === 2, 'text-700 border-transparent': tab !== 2}" @click="changeTab(2)">
                <i class="fa-solid fa-file-signature mr-2"></i>
                <span class="font-medium">Documents</span>
              </a>
            </li>
            <li v-if="independent.status !== null && independent.status !== constants.INDEPENDENT_STATUS.DRAFT">
              <a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 p-ripple"
                 :class="{'border-primary-500 text-primary-500 hover:primary-blue-500': tab === 3, 'text-700 border-transparent': tab !== 3}" @click="changeTab(3)">
                <i class="fa-solid fa-file-signature mr-2"></i>
                <span class="font-medium">Sortie</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="me?.role.name === constants.ROLES.ADMIN" class="flex-container non-scrollable">
      <r-h-profil v-if="tab === 0" :independent="independent" @updateIndependent="initIndependent"/>
      <simulation v-if="tab === 1" :independent="independent"/>
      <documents-r-h v-if="tab === 2 && independent" :independent="independent"/>
      <sortie v-if="tab === 3 && independent" :independent="independent"/>
    </div>
    <div v-else class="flex-container non-scrollable">
      <r-h-profil v-if="tab === 0" :independent="independent"/>
      <r-h-simulation-porte v-if="tab === 1 && independent" :independent="independent"/>
      <documents-r-h v-if="tab === 2 && independent" :independent="independent" />
      <sortie-independent v-if="tab === 3 && independent" :independent="independent" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NameHeader from '@/components/NameHeader'
import RHProfil from '@/views/Pages/RH/RHProfil'
import DocumentsRH from "@/views/Pages/RH/DocumentsRH";
import Sortie from "@/views/Pages/RH/Sortie";
import Simulation from "@/views/Pages/RH/Simulation";
import RHSimulationPorte from '@/views/Pages/RH/SimulationPorte'
import SortieIndependent from "@/views/Pages/RH/SortieIndependent";

export default {
  name: 'RH',
  components: {
    SortieIndependent,
    RHSimulationPorte,
    Simulation,
    Sortie,
    DocumentsRH,
    NameHeader,
    RHProfil
  },
  data () {
    return {
      tab: 0
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      constants: state => state.constants,
      contentChanged: state => state.misc.contentChanged,
      sideContent: state => state.sidebar.sideContent,
      independent: state => state.independent.independent,
      independents: state => state.independent.independents
    })
  },
  watch: {
    // '$route.query.idIndependent': {
    //   handler (val) {
    //     this.handleIndep(val)
    //   },
    //   deep: true
    // },
    async tab(val) {
      if (val === 0) {
        await this.initIndependent()
      }
    },
  },
  async created () {
    await this.initIndependent()
  },
  methods: {
    async initIndependent () {
      if (this.me?.role.name === this.constants.ROLES.ADMIN) {
        await this.handleIndep(this.$route.query.idIndependent)
      } else {
        await this.$store.dispatch('independent/getOneIndependentByUser', this.me.id)
        await this.handleIndep(this.independent.id)
      }
    },
    async handleIndep (val) {
      if (val) {
        await this.$store.dispatch('independent/getOneIndependent', val)
      } else if (this.me?.role.name === this.constants.ROLES.ADMIN) {
        this.$store.commit('independent/SET_INDEPENDENT', null)
      }
    },
    async changeTab(tab) {
      let routeName = this.me?.role.name === this.constants.ROLES.ADMIN ? 'RHProfil' : 'FicheRHIndependent'
      switch (tab) {
        case 1:
          routeName = this.me?.role.name === this.constants.ROLES.ADMIN ? 'RHSimulation' : 'RHSimulationIndependent'
          break
        case 2:
          routeName = this.me?.role.name === this.constants.ROLES.ADMIN ? 'RHDocumentsAdmin' : 'RHDocumentsIndependent'
          break
        case 3:
          routeName = this.me?.role.name === this.constants.ROLES.ADMIN ? 'RHSortieAdmin' : 'RHSortieIndependent'
          break
        default:
          break
      }
      await this.$router.push({name: routeName, query: this.$route.query})
      this.tab = tab
    }
  }
}
</script>

<style scoped>

</style>
