<template>
  <div>
    <contracts-amendments :documents="docs" :loading="loading" :fields="fields" @refreshContracts="initContracts"/>
  </div>
</template>

<script>
import ContractsAmendments from "@/components/Contracts/ContractsAmendments.vue";

export default {
  name: "WorkContractsAmendments",
  components: {ContractsAmendments},
  props: {
    independentId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fields: [
        'filename',
        'month_uploaded',
        'year_uploaded',
        'initial_import_date'
      ],
      docs: [],
      loading: true,
    }
  },
  async created() {
    await this.initContracts()
  },
  methods: {
    async initContracts() {
      this.loading = true
      this.docs = await this.$store.dispatch('documents/getAllIndependentWorkContracts', {id: this.independentId, sorts: ['-date_created'] })
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
