<template>
  <div>
    <DataTable :value="docs" responsiveLayout="scroll" row-hover class="p-datatable-sm" selectionMode="single"
               scrollable scroll-height="300px"
               @row-click="openContractPreview">
      <Column field="document.title" header="Nom du fichier">
        <template #body="{data}">
          <span class="text-blue-500">
            {{ data?.document?.title }}
          </span>
        </template>
      </Column>
      <Column field="month" header="Mois"></Column>
      <Column field="year" header="Année"></Column>
    </DataTable>
  </div>
  <dialog-file-preview v-if="previewContractId" :is-active="previewPaySlipDialog" :file-id="previewContractId"
                       :document-id="paySlipDocumentId" :document-type="paySlipDocumentType" validation-dialog
                       dialog-title="Bulletin de Salaire" :delete-button="me.role.name === constants.ROLES.ADMIN" replace-file-button
                       @close-dialog="closeFilePreview" @deleteFile="refreshData" @replaceFile="refreshData"/>
</template>

<script>

import DialogFilePreview from "@/components/DialogFilePreview.vue";
import {mapState} from "vuex";

export default {
  components: {DialogFilePreview},
  props: ["docs"],
  name: "TableauBDS",
  data () {
    return {
      previewPaySlipDialog: false,
      previewContractId: null,
      paySlipDocumentId: null,
      paySlipDocumentType: null
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      constants: state => state.constants
    })
  },
  methods: {
    async openContractPreview (event) {
      this.previewContractId = event.data.document.id
      this.paySlipDocumentId = event.data.id
      this.paySlipDocumentType = event.data.type
      this.previewPaySlipDialog= true
    },
    closeFilePreview () {
      this.previewContractId = null
      this.previewPaySlipDialog= false
    },
    refreshData () {
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>
.link-hover :hover {
  cursor: pointer;
}
</style>
