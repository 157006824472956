<template>
  <div v-if="independent" class="flex-container non-scrollable">
    <div class="flex justify-content-between">
      <div class="flex flex-row p-3 gap-2 xhl:px-5">
        <Button v-if="me.role.name === constants.ROLES.ADMIN" label="DPAE"
                class="p-button-outlined p-button-rounded h-auto w-full px-3 xxl:w-auto" size="small"
                @click="openDPAE"/>
        <Button label="Voir le contrat" class="p-button-rounded h-auto w-full px-3 xxl:w-auto" size="small"
                :disabled="!independent.last_document_contract" @click="downloadContractFile"/>
        <Button :label="$t('save')" class="p-button-rounded h-auto w-full px-3 xxl:w-auto" size="small"
                @click="prepareConfirm"/>
        <Button
            v-if="me.role.name === constants.ROLES.ADMIN &&
            (independent?.last_document_contract?.status === constants.DOCUMENT_STATUS.PENDING_SIGNATURE || independent?.last_document_contract?.status === constants.DOCUMENT_STATUS.CANCELLED)"
            :disabled="!independent?.last_document_contract?.metadata?.signatureRequestId || independent?.last_document_contract?.status === constants.DOCUMENT_STATUS.CANCELLED"
            :label="independent?.last_document_contract?.status === constants.DOCUMENT_STATUS.CANCELLED ? 'Annulation du contrat en cours' : 'Annuler le contrat en attente de signature'"
            class="p-button-rounded p-button-warning" size="small"
            @click="cancelContractSignatureConfirmation"
        />
        <Button
            v-if="showAmendmentContractUploadingWays"
            label="Importer un avenant"
            class="p-button-rounded h-auto"
            @click="importAmendmentContractAction"
        />
        <Button
            v-if="showAmendmentContractUploadingWays"
            label="Générer un avenant"
            class="p-button-rounded h-auto"
            @click="openSendDocumentDialog = true"
        />
        <Button
            v-if="showFirstContractUploadingWays"
            label="Importer le contrat"
            class="p-button-rounded h-auto"
            @click="importContractAction"
        />
        <Button
            v-if="showFirstContractUploadingWays"
            :label="$t('contractualisation')"
            class="p-button-rounded h-auto"
            @click="openSendDocumentDialog = true"
        />
      </div>
    </div>
    <div class="scrollable mt-2">
      <profil
          ref="profil"
          v-if="localIndependent"
          :key="keyProfile"
          :independent="localIndependent"
          :step="{label: ''}"
          :view-r-h="true"
          class="pt-3"
          @on-profile-field-change="profileFieldChange"
      />
      <div class="spacer"></div>
    </div>
    <!--  todo: when some free time, merge use the component dialogFilePreview and set it up properly  -->
    <dialog-contract-upload
        :is-active="openContractImportDialog"
        @closeDialog="openContractImportDialog = false"
        @importContractFile="importContract"
    />
    <dialog-contractualiser-contrat
        :is-active="openSendDocumentDialog"
        :independentId="independent.id"
        :userId="independent.user"
        :amendment="independent.last_document_contract !== null"
        :independent-contract-metadata="independent.contract_metadata"
        @closeDialog="openSendDocumentDialog = false"
    />
    <dialog-confirm
        :is-active="confirmDialog"
        :refuse-action="refuseAction"
        :validate-action="validateAction"
        :text-question="textQuestion"
        :text-info="textInfo"
        :title="title"
    />
  </div>
</template>

<script>
import Profil from '@/components/Profil/ProfilValidation/Steps/Profil'
import DialogContractualiserContrat from "@/components/Mission/DialogContractualiserContrat";
import {mapState} from "vuex";
import DialogContractUpload from "@/components/Mission/DialogContractUpload.vue";
import DialogConfirm from "@/components/DialogConfirm.vue";
import {disablePComponentsOfCurrentPage, hasAccess} from "@/utils/Misc";
import Alert from "@/utils/Alert";
import getErrorKeySignature from "@/utils/GetErrorKeySignature";

export default {
  name: 'RHProfil',
  components: {DialogConfirm, DialogContractUpload, DialogContractualiserContrat, Profil },
  data () {
    return {
      active: 0,
      localIndependent: undefined,
      openSendDocumentDialog: false,
      keyProfile: 0,
      workContractDocumentType: {
        name: this.$t('workContractDocumentName')
      },

      openContractImportDialog: false,
      importContract: () => {},

      confirmDialog: false,
      refuseAction: () => {},
      validateAction: () => {},
      textQuestion: '',
      textInfo: '',
      title: ''
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      constants: state => state.constants,
      SAVE_FLAG: state => state.misc.SAVE_FLAG,
      independent: state => state.independent.independent
    }),
    showAmendmentContractUploadingWays () {
      return this.me.role.name === this.constants.ROLES.ADMIN && this.independent?.status !== this.constants.INDEPENDENT_STATUS.DRAFT &&
          (this.independent?.last_document_contract?.type === this.constants.DOCUMENT_TYPE.WORK_CONTRACT || this.independent?.last_document_contract?.type === this.constants.DOCUMENT_TYPE.WORK_CONTRACT_AMENDMENT) &&
          (this.independent?.last_document_contract?.status === this.constants.DOCUMENT_STATUS.SIGNED || this.independent?.last_document_contract?.status === this.constants.DOCUMENT_STATUS.VALIDATED ||
              this.independent?.last_document_contract?.status === this.constants.DOCUMENT_STATUS.CANCELLED)
    },
    showFirstContractUploadingWays () {
      return this.me.role.name === this.constants.ROLES.ADMIN && this.independent?.status === this.constants.INDEPENDENT_STATUS.DRAFT &&
          (!this.independent.last_document_contract || this.independent?.last_document_contract?.status === this.constants.DOCUMENT_STATUS.CANCELLED)
    }
  },
  watch: {
    independent: {
      handler () {
        this.localIndependent = this.independent
        // this.localIndependent
        // ++this.keyProfile
      },
      deep: true
      // handler () {
      //   if (this.localIndependent && this.localIndependent.id !== this.independent?.id) {
      //     this.localIndependent = null
      //     this.updateIndependent()
      //   }
      //   if (this.localIndependent === undefined) {
      //     this.updateIndependent()
      //   }
      // }
    },
    async SAVE_FLAG(val) {
      if (val) {
        await this.save()
      }
    },
  },
  created () {
    this.updateIndependent()
  },
  methods: {
    openDPAE () {
      window.open('https://www.due.urssaf.fr/declarant/arLibre.jsf','_blank')
    },
    updateIndependent () {
      // if (this.independent && this.independent.user) {
      //   this.$store.state.misc.loading = true
      //   this.$store.dispatch('independent/getOneIndependentByUser', this.independent.user.id ? this.independent.user.id : this.independent.user).then((res) => {
      //     this.$nextTick(() => {
      //       if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
      //     })
      //     this.$store.state.misc.loading = false
      //     this.localIndependent = res
      //   })
      // } else {
      //   this.$nextTick(() => {
      //     if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
      //   })
      //   this.$emit('updateIndependent')
      //   this.localIndependent = undefined
      //
      //
      // }

      this.$nextTick(() => {
        if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
      })
      this.localIndependent = this.independent
    },
    async save () {
      if (hasAccess(this.$route.name, true)) {
        await this.$refs.profil.onSubmit()
      }
    },
    async prepareConfirm () {
      if (hasAccess(this.$route.name, true)) {
        if (this.me.role.name === this.constants.ROLES.INDEPENDENT) {
          this.refuseAction = () => {
            this.confirmDialog = false
          }
          this.validateAction = async () => {
            await this.save()
            this.confirmDialog = false
          }
          this.title =  'Confirmation enregistrement'
          this.textQuestion =  'Êtes vous sur de vos changements ?'
          this.textInfo = 'Vos changement s\'appliqueront si vous validez.'
          this.confirmDialog = true
        } else {
          await this.save()
          await this.$store.dispatch('misc/updateContentChanged', false)
        }
      }
    },
    async downloadContractFile () {
      let fileO = this.independent.last_document_contract.document

      const file = await this.$store.dispatch('file/downloadFile', fileO)
      const blob = new Blob([new Uint8Array(file.data.data)], { type: file.mimetype })
      const link = document.createElement('a')
      link.download = file.filename
      const url = URL.createObjectURL(blob)
      link.href = url
      link.click()
      URL.revokeObjectURL(url)
    },
    // eslint-disable-next-line no-unused-vars
    async profileFieldChange ({mission, fieldOption}) {
      await this.$store.dispatch('misc/updateContentChanged', true)

      // todo: later add the amendment force too
      // // force the amendment in case of saving
      // this.isAmendmentNeeded = this.isAmendmentNeeded ? true : fieldOption.isAmendment
    },
    importContractAction () {
      if (hasAccess(this.$route.name, true)) {
        this.importContract = async (payload) => {
          await this.$store.dispatch('rh/importContract', {
            id: this.independent.id,
            userId: this.independent.user,
            form: payload.form
          })
        }
        this.openContractImportDialog = true
      }
    },
    importAmendmentContractAction () {
      if (hasAccess(this.$route.name, true)) {
        this.importContract = async (payload) => {
          await this.$store.dispatch('rh/importContract', {
            id: this.independent.id,
            userId: this.independent.user,
            form: payload.form,
            isAmendment: true
          })
        }
        this.openContractImportDialog = true
      }
    },
    cancelContractSignatureConfirmation () {
      if (hasAccess(this.$route.name, true)) {
        this.refuseAction = () => {
          this.confirmDialog = false
        }
        this.validateAction = async () => {
          await this.cancelContractSignature()
          this.confirmDialog = false
        }
        this.title =  'Confirmation Annulation'
        this.textQuestion =  'Êtes vous sur d\'annuler la signature du contrat ?'
        this.textInfo = 'Le contrat sera supprimé si vous validez et vous pourrez importer ou générer un nouveau contrat'
        this.confirmDialog = true
      }
    },
    async cancelContractSignature () {
      try {
        await this.$store.dispatch('rh/cancelWorkContractSignature', {
          signatureRequestId: this.independent.last_document_contract.metadata.signatureRequestId,
          documentId: this.independent.last_document_contract.id
        })
        await this.updateIndependent()
        Alert.successMessage(this, 'cancelContractSignature')
      } catch (e) {
        Alert.errorMessage(this, getErrorKeySignature(e))
      }
    }
  }
}
</script>

<style scoped>

</style>
