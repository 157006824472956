<template>
  <div v-if="loaded" class="flex flex-column flex-grow-1 scrollable">
    <div class="pl-0 pt-0 pr-0 pb-3 flex align-items-center justify-content-between xhl:px-5">
      <div class="flex align-items-center" style="gap: 1.5rem;">
        <div :class="tab === 0 ? 'font-bold' : ''" class="cursor-pointer text-xl" @click="changeTab(0)">Ancienne simulation</div>
        <div :class="tab === 1 ? 'font-bold' : ''" class="cursor-pointer text-xl" @click="changeTab(1)">Simulation à valider</div>
      </div>
      <div v-if="tab === 1" class="flex align-items-center" style="gap: 1.5rem;">
        <Button label="Accepter la simulation" class="p-button-rounded" :disabled="simulation.status === constants.SIMULATION_STATUS.VALIDATED" @click="validateSimulation" />
      </div>
    </div>
    <simulation v-if="tab === 0" :idSimulation="simulation.id"/>
    <fiche-simulation v-if="tab === 1" :key="simulationViewKey" read-only
                      @changeSimulation="changeSimulation"/>
  </div>
</template>
<script>

import PdfGenerator from '@/utils/PdfGenerator'
import { mapState } from 'vuex'
import Alert from '@/utils/Alert'
import Simulation from '@/views/Pages/Prospect/Simulation'
import FicheSimulation from "@/components/FicheSimulation";
import {disablePComponentsOfCurrentPage, hasAccess} from "@/utils/Misc";
export default {
  name: 'RHSimulationPorte',
  components: {FicheSimulation, Simulation },
  props: ['independent'],
  data () {
    return {
      tab: 0,
      simulationViewKey: 0,
      loaded: false
    }
  },
  computed: {
    ...mapState({
      simulation: state => state.simulation.simulation,
      simulations: state => state.simulation.simulations,
      settings: state => state.misc.settings,
      constants: state => state.constants
    })
  },
  async created () {
    this.loaded = false
    await this.updateSimulations()
    this.loaded = true
  },
  mounted () {
    this.$nextTick(() => {
      if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
    })
  },
  methods: {
    async changeTab (tab) {
      if (tab === 0) {
        // await this.$router.push({ name: 'FicheMissionPorte', params: { idPorte: this.$route.params.idPorte, idMission: this.mission?.id?? this.missionsIndependent[0].id }} )
        this.tab = 0
      } else {
        // await this.$router.push({ name: 'FicheSimulationPorte', params: { idPorte: this.$route.params.idPorte, idSimulation: this.simulation?.id?? 'new' }} )
        this.$store.dispatch('simulation/getSimulationsIndependent', this.independent.id)
        this.tab = 1
      }
    },
    async updateSimulations () {
      if (this.independent) {
        if (this.tab === 0) {
          await this.$store.dispatch('simulation/getValidatedSimulationsIndependent', this.independent.id)
        } else {
          await this.$store.dispatch('simulation/getSimulationsIndependent', this.independent.id)
        }
        await this.$store.dispatch('simulation/getLastSimulation', this.independent.id)
      }
    },
    generateSimulation () {
      this.$store.dispatch('simulation/generateSimulation', { id: this.simulation.id }).then((simu) => {
        this.simulationGenerated = simu.simulation
        this.pdfGenerator = new PdfGenerator({ ...this.simulationGenerated, settings: this.settings, constants: this.constants }, 'simulationV2')
        this.pdfGenerator.getPdf((dataUrl) => {
          this.pdf = dataUrl
          this.showSimulation = true
        })
      })
    },
    async save () {
      if (hasAccess(this.$route.name, true)) {
        if (!this.simulation.commercial) {
          Alert.errorMessage(this, 'fieldRequired', {symbole: '(1)'})
        } else {

          if (!(this.constants.REGEX_VALIDATORS.EMAIL.test(this.simulation.independent.email))) {
            Alert.errorMessage(this, 'invalidEmail')
            return
          }

          await this.$store.dispatch('simulation/saveSimulation', this.simulation).then(() => {
            Alert.successMessage(this, 'saveSimulation')
            this.$refs.ficheSimu.cloneSimulation()
          })
        }
      }
    },
    async changeSimulation (simulationId) {
      await this.$store.dispatch('simulation/getSimulation', simulationId)
      ++this.simulationViewKey
    },
    async validateSimulation () {
      // validate simulation with the role associated
      // prospect only validate
      // independent validated only one and the others toValidate simulations are archived
      if (hasAccess(this.$route.name, true)) {
        await this.$store.dispatch('simulation/validateSimulation', { id: this.simulation.id, simulations: this.simulations })
      }
    }
  }
}
</script>

<style scoped>

</style>
